import React, { memo } from "react";
import { timestampToDateString } from "../../../../app/utils";
import { makeContainer } from "../../../../components/container/container";
import NoData from "../../../../components/no-data/no-data";
import { Table } from "../../../../components/table/table";
import MapsService from "../../../../features/maps/service";
import {
  Permission,
  PermissionsData,
} from "../../../../features/permission/type";
import "./profile-plans.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  permissions?: PermissionsData;
  permission?: Permission;
  isOk?: boolean;
  state?: string;
};

const ProfilePlansContainer = makeContainer("profile-plans-container");

const StateFlag: React.FC<Props> = ({ state }) => {
  const service = new MapsService();

  return (
    <div className="profile-plans-state-flag">
      <img
        className="profile-plans-state-flag-image"
        alt="bandeira do estado"
        src={service.getStateFlag(state)}
      />
      {state}
    </div>
  );
};

const ProfilePlansItemBox: React.FC<Props> = ({ children, isOk }) => {
  return (
    <div
      className={`profile-plans-item-box ${
        isOk ? "profile-plans-item-box-ok" : "profile-plans-item-box-no-ok"
      }`}
    >
      {children}
    </div>
  );
};

const ProfilePlans: React.FC<Props> = ({ permissions }) => {
  const hasPermissions = permissions?.parsedPermissions?.length > 0;
  const perms = permissions?.parsedPermissions;
  const permsWithYears = perms?.map((p) => ({
    ...p,
    years: p.years.join(", "),
  }));
  const permsWithExpTime = permsWithYears?.map((p) => ({
    ...p,
    expiration: timestampToDateString(p.expirationTime),
  }));
  const colsBaseStyle = {
    display: "flex",
    justifyContent: "flex-start",
  };

  return (
    <ProfilePlansContainer>
      {!hasPermissions && <NoData noDataText="sem planos" />}
      {hasPermissions && (
        <Table
          data={permsWithExpTime}
          columns={[
            {
              label: "Estado",
              style: {
                ...colsBaseStyle,
                width: "35%",
              },
            },
            {
              label: "Meta",
              style: {
                ...colsBaseStyle,
                width: "25%",
              },
            },
            {
              label: "Expiração",
              style: {
                ...colsBaseStyle,
                width: "30%",
              },
            },
          ]}
          cells={[
            ({ row }) => <StateFlag state={row.state} />,
            ({ row }) => (
              <ProfilePlansItemBox isOk={row.hasTarget}>
                {row.hasTarget ? "Sim" : "Não"}
              </ProfilePlansItemBox>
            ),
            ({ row }) => (
              <ProfilePlansItemBox isOk={!row.hasExpired}>
                {row.expiration}
              </ProfilePlansItemBox>
            ),
          ]}
        />
      )}
    </ProfilePlansContainer>
  );
};

export default memo(ProfilePlans);
