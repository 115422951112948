import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Attribute from "../../../app/attribute";
import { DashboardFilterConsts, Mode } from "../../../app/constants";
import { hasFilter } from "../../../app/filter";
import { AppDispatch } from "../../../app/store";
import { toPtBr } from "../../../app/utils";
import NumberWidget from "../../../components/dashboard/number-widget/number-widget";
import {
  electorateService,
  votingService,
} from "../../../features/dashboard/service";
import {
  getLeftNumberWidgetData,
  getRightNumberWidgetData,
  selectAttributes,
  selectComparativeMode,
  selectDashboardComparativeFilters,
  selectDashboardFilters,
  selectLeftNumberWidgetAttribute,
  selectLeftWidgetData,
  selectLeftWidgetDataStatus,
  selectRightNumberWidgetAttribute,
  selectRightWidgetData,
  selectRightWidgetDataStatus,
  selectZoneMode,
  setLeftWidgetAttribute,
  setRightWidgetAttribute,
} from "../../../features/dashboard/slice";
import {
  QueryResult,
  ScalarAttributeData,
} from "../../../features/dashboard/types";

export enum WidgetSide {
  LEFT = "left",
  RIGHT = "right",
}

type Props = {
  side: WidgetSide;
  mode: Mode;
};

const getService = (mode: Mode) => {
  return mode === Mode.VOTING ? votingService : electorateService;
};

const getValidAttribute = (
  mode: Mode,
  side: WidgetSide,
  attributes: Attribute[]
) => {
  const origin = mode === Mode.VOTING ? "voting" : "electorate";
  const selectedAttributes = attributes.filter(
    (a) => a.type === "scalar" && a.origin === origin
  );
  return side === WidgetSide.LEFT
    ? selectedAttributes[0]
    : selectedAttributes[1];
};

const isFromCurrentMode = (mode: Mode, attribute: Attribute) => {
  const origin = mode === Mode.VOTING ? "voting" : "electorate";
  return attribute.origin === origin;
};

const getSetAttributeAction = (side: WidgetSide) => {
  return side === WidgetSide.LEFT
    ? setLeftWidgetAttribute
    : setRightWidgetAttribute;
};

const NumberWidgetWithData: React.FC<Props> = ({ side, mode }) => {
  const dispatch: AppDispatch = useDispatch();
  const attributes: Attribute[] = useSelector(selectAttributes);
  const dashFilters = useSelector(selectDashboardFilters);
  const dashComparativeFilters = useSelector(selectDashboardComparativeFilters);
  const comparativeMode: boolean = useSelector(selectComparativeMode);
  const zoneMode: boolean = useSelector(selectZoneMode);

  const data: QueryResult = useSelector(
    side === WidgetSide.LEFT ? selectLeftWidgetData : selectRightWidgetData
  );

  const dataStatus = useSelector(
    side === WidgetSide.LEFT
      ? selectLeftWidgetDataStatus
      : selectRightWidgetDataStatus
  );

  const attribute = useSelector(
    side === WidgetSide.LEFT
      ? selectLeftNumberWidgetAttribute
      : selectRightNumberWidgetAttribute
  );

  const dataSource = (
    comparativeMode && data?.comparative_result
      ? data?.comparative_result
      : data?.scenario_result
  ) as ScalarAttributeData;

  useEffect(() => {
    const setInitialAttribute = () => {
      if (attributes) {
        const newAttribute = getValidAttribute(mode, side, attributes);
        if (newAttribute) {
          const setAttributeAction = getSetAttributeAction(side);
          dispatch(setAttributeAction(newAttribute));
        }
      }
    };

    const cleanUpAttribute = () => {
      const setAttributeAction = getSetAttributeAction(side);
      dispatch(setAttributeAction(null));
    };

    setInitialAttribute();
    return cleanUpAttribute;
  }, [dispatch, mode, side, attributes]);

  useEffect(() => {
    const fetchData = () => {
      if (
        attribute &&
        isFromCurrentMode(mode, attribute) &&
        hasFilter(dashFilters, DashboardFilterConsts.YEAR)
      ) {
        const getDataAction =
          side === WidgetSide.LEFT
            ? getLeftNumberWidgetData
            : getRightNumberWidgetData;

        dispatch(
          getDataAction({
            attribute: attribute,
            filters: dashFilters,
            comparativeFilters: dashComparativeFilters,
            comparativeMode,
            zoneMode,
            service: getService(mode),
          })
        );
      }
    };

    fetchData();
  }, [
    dispatch,
    mode,
    side,
    zoneMode,
    attribute,
    dashFilters,
    dashComparativeFilters,
    comparativeMode,
  ]);

  return (
    <NumberWidget
      attribute={attribute}
      attributes={attributes}
      value={toPtBr(dataSource?.value)}
      onAttributeSelected={(newAttribute) => {
        const setAttributeAction = getSetAttributeAction(side);
        dispatch(setAttributeAction(newAttribute));
      }}
      status={dataStatus}
    />
  );
};

export default NumberWidgetWithData;
