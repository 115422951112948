import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Pages, ShopPages } from "../../app/constants";
import { selectIsLogged } from "../../features/auth/slice";
import PageNotFound from "../../pages/404/not-found";
import ShopLogin from "../../pages/shop/auth/login";
import ShopSignup from "../../pages/shop/auth/signup";
import Cart from "../../pages/shop/checkout/cart";
import Checkout from "../../pages/shop/checkout/checkout";
import OrderDetails from "../../pages/shop/order/order-details";
import Orders from "../../pages/shop/order/orders";
import PlanSelect from "../../pages/shop/plans/plan-select";
import ProductSelect from "../../pages/shop/product/product-select";
import ReportCandidateSelect from "../../pages/shop/report/report-candidate-select";
import ReportCandidatureSelect from "../../pages/shop/report/report-candidature-select";
import ReportTypeSelect from "../../pages/shop/report/report-type-select";
import { makeContainer } from "../container/container";
import { ShopMenu } from "./menu";
import "./page.css";

type PageProps = {
  title?: string | JSX.Element | JSX.Element[];
  page?: Pages;
  children?: JSX.Element;
};

const PageContainer = makeContainer("shop-page-container");
const Main = makeContainer("shop-main");

const PageContent = {
  [ShopPages.LOGIN]: ShopLogin,
  [ShopPages.SIGNUP]: ShopSignup,
  [ShopPages.CART]: Cart,
  [ShopPages.CHECKOUT]: Checkout,
  [ShopPages.ORDER_DETAILS]: OrderDetails,
  [ShopPages.ORDERS]: Orders,
  [ShopPages.PLANS]: PlanSelect,
  [ShopPages.PRODUCT]: ProductSelect,
  [ShopPages.REPORT_CANDIDATE]: ReportCandidateSelect,
  [ShopPages.REPORT_CANDIDATURE]: ReportCandidatureSelect,
  [ShopPages.REPORT_TYPE]: ReportTypeSelect,
};

const PagesRequiringLogin = [
  ShopPages.CART,
  ShopPages.CHECKOUT,
  ShopPages.ORDER_DETAILS,
  ShopPages.ORDERS,
];

type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  outlet: JSX.Element;
};

function ProtectedRoute({
  isAuthenticated,
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {
  if (isAuthenticated) {
    return outlet;
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />;
  }
}

function ProtectedPage(defaultProtectedRouteProps) {
  return <ProtectedRoute {...defaultProtectedRouteProps} outlet={<Outlet />} />;
}

export const ShopPage: React.FC<PageProps> = ({ children, title, page }) => {
  const isLogged = useSelector(selectIsLogged);

  const protetecedProps: Omit<ProtectedRouteProps, "outlet"> = {
    isAuthenticated: isLogged,
    authenticationPath: ShopPages.LOGIN,
  };

  return (
    <PageContainer>
      <ShopMenu />
      <Main>
        <Routes>
          <Route element={<ProtectedPage {...protetecedProps} />}>
            {Object.keys(PageContent)
              .filter((key) => PagesRequiringLogin.includes(key as ShopPages))
              .map((key) => {
                const ElementPage = PageContent[key];
                return <Route key={key} path={key} element={<ElementPage />} />;
              })}
          </Route>
          {Object.keys(PageContent)
            .filter((key) => !PagesRequiringLogin.includes(key as ShopPages))
            .map((key) => {
              const ElementPage = PageContent[key];
              return <Route key={key} path={key} element={<ElementPage />} />;
            })}
          <Route path={""} element={<ProductSelect />} />
          <Route path={"*"} element={<PageNotFound />} />
        </Routes>
      </Main>
    </PageContainer>
  );
};

export default ShopPage;
