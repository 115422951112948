import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Attribute from "../../../../app/attribute";
import { Mode } from "../../../../app/constants";
import { AppDispatch } from "../../../../app/store";
import { toPtBr } from "../../../../app/utils";
import NumberWidget from "../../../../components/dashboard/number-widget/number-widget";
import {
  getLeftNumberWidgetData,
  getRightNumberWidgetData,
  selectAttributes,
  selectDashboardComparativeFilters,
  selectDashboardFilters,
  selectLeftNumberWidgetAttribute,
  selectLeftWidgetData,
  selectLeftWidgetDataStatus,
  selectRightNumberWidgetAttribute,
  selectRightWidgetData,
  selectRightWidgetDataStatus,
  selectZoneMode,
  setLeftWidgetAttribute,
  setRightWidgetAttribute,
} from "../../../../features/dashboard/slice";
import {
  QueryResult,
  ScalarAttributeData,
} from "../../../../features/dashboard/types";
import TargetService from "../../../../features/target/service";
import { selectTargetUpdateCounter } from "../../../../features/target/slice";

export enum WidgetSide {
  LEFT = "left",
  RIGHT = "right",
}

type Props = {
  side: WidgetSide;
  mode?: Mode;
};

const getSetAttributeAction = (side: WidgetSide) => {
  return side === WidgetSide.LEFT
    ? setLeftWidgetAttribute
    : setRightWidgetAttribute;
};

const NumberWidgetWithData: React.FC<Props> = ({ side, mode }) => {
  const dispatch: AppDispatch = useDispatch();
  const attributes: Attribute[] = useSelector(selectAttributes);
  const dashFilters = useSelector(selectDashboardFilters);
  const dashComparativeFilters = useSelector(selectDashboardComparativeFilters);
  const targetUpdateCounter: number = useSelector(selectTargetUpdateCounter);
  const zoneMode: boolean = useSelector(selectZoneMode);

  const data: QueryResult = useSelector(
    side === WidgetSide.LEFT ? selectLeftWidgetData : selectRightWidgetData
  );

  const dataStatus = useSelector(
    side === WidgetSide.LEFT
      ? selectLeftWidgetDataStatus
      : selectRightWidgetDataStatus
  );

  const attribute = useSelector(
    side === WidgetSide.LEFT
      ? selectLeftNumberWidgetAttribute
      : selectRightNumberWidgetAttribute
  );

  const dataSource = data?.scenario_result as ScalarAttributeData;

  useEffect(() => {
    const fetchData = () => {
      if (attribute) {
        const getDataAction =
          side === WidgetSide.LEFT
            ? getLeftNumberWidgetData
            : getRightNumberWidgetData;

        dispatch(
          getDataAction({
            attribute: attribute,
            filters: dashFilters,
            comparativeFilters: dashComparativeFilters,
            comparativeMode: targetUpdateCounter,
            zoneMode,
            service: new TargetService(),
          })
        );
      }
    };

    fetchData();
  }, [
    dispatch,
    mode,
    side,
    zoneMode,
    attribute,
    dashFilters,
    dashComparativeFilters,
    targetUpdateCounter,
  ]);

  return (
    <NumberWidget
      attribute={attribute}
      attributes={attributes}
      value={toPtBr(dataSource?.value)}
      onAttributeSelected={(newAttribute) => {
        const setAttributeAction = getSetAttributeAction(side);
        dispatch(setAttributeAction(newAttribute));
      }}
      status={dataStatus}
    />
  );
};

export default NumberWidgetWithData;
