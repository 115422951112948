import { memo } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Status } from "../../../../../app/constants";
import { toPtBr, toPtBrContracted } from "../../../../../app/utils";
import CustomResponsiveContainer from "../../../../../components/charts/CustomResponsiveContainer";
import Loading from "../../../../../components/loading/loading";
import NoData from "../../../../../components/no-data/no-data";
import { PartyProfileData } from "../../../../../features/parties/type";
import "./party-candidates-chart.css";

type Props = {
  summary?: PartyProfileData;
  status?: Status;
};

const format = (summary: PartyProfileData) => {
  let candidates = summary?.candidates?.slice();
  if (!candidates) return [];

  candidates?.sort((v1, v2) => {
    return v1.year !== v2.year ? v1.year - v2.year : v1.turn - v2.turn;
  });

  const yearGroups = {};
  for (const c of candidates) {
    if (!(c.year in yearGroups)) {
      yearGroups[c.year] = {
        Ano: c.year,
        "Candidatos 1º Turno": 0,
        "Eleitos 1º Turno": 0,
        "Candidatos 2º Turno": 0,
        "Eleitos 2º Turno": 0,
      };
    }

    if (c.turn === 1) {
      yearGroups[c.year]["Candidatos 1º Turno"] = c.candidates;
      yearGroups[c.year]["Eleitos 1º Turno"] = c.elected_candidates;
    } else {
      yearGroups[c.year]["Candidatos 2º Turno"] = c.candidates;
      yearGroups[c.year]["Eleitos 2º Turno"] = c.elected_candidates;
    }
  }

  const data = Object.keys(yearGroups).map((key) => yearGroups[key]);

  data.sort((v1, v2) => {
    return v1.Ano - v2.Ano;
  });

  return data;
};

const formatTicks = (tickItem) => {
  return toPtBrContracted(tickItem);
};

const CustomizedTooltip = ({ payload, label, active }) => {
  if (active) {
    return (
      <div className="barchart-custom-tooltip">
        <div>{`${label}`}</div>
        <div>{`1º Turno ${toPtBr(payload[1].value)} candidato(s)`}</div>
        <div>{`2º Turno ${toPtBr(payload[0].value)} candidato(s)`}</div>
        <div>{`1º Turno ${toPtBr(payload[3].value)} eleitos(s)`}</div>
        <div>{`2º Turno ${toPtBr(payload[2].value)} eleitos(s)`}</div>
      </div>
    );
  }

  return null;
};

const PartyCandidatesChart: React.FC<Props> = ({ summary, status }) => {
  const data = format(summary);

  if (status === Status.LOADING) {
    return <Loading />;
  }

  if (!summary?.candidates || !summary?.candidates.length) {
    return <NoData />;
  }

  return (
    <CustomResponsiveContainer>
      <ResponsiveContainer width="99%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 25,
            right: 15,
            left: 35,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={CustomizedTooltip} />
          <XAxis dataKey="Ano" />
          <YAxis
            yAxisId="left"
            orientation="left"
            stroke="#2DA5C0"
            tickFormatter={formatTicks}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            stroke="#E76E6E"
            tickFormatter={formatTicks}
          />

          <Bar
            yAxisId="left"
            dataKey="Candidatos 2º Turno"
            fill="#2DA5C0"
            stackId="candidates"
          />
          <Bar
            yAxisId="left"
            dataKey="Candidatos 1º Turno"
            fill="#2DA5C0"
            stackId="candidates"
            radius={[6, 6, 0, 0]}
          />

          <Bar
            yAxisId="right"
            dataKey="Eleitos 2º Turno"
            fill="#E76E6E"
            stackId="elected"
          />

          <Bar
            yAxisId="right"
            dataKey="Eleitos 1º Turno"
            fill="#E76E6E"
            stackId="elected"
            radius={[6, 6, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </CustomResponsiveContainer>
  );
};

export default memo(PartyCandidatesChart);
