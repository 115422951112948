import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ShopPages } from "../../../../app/constants";
import { AppDispatch } from "../../../../app/store";
import {
  makeContainer,
  makeSpanContainer,
} from "../../../../components/container/container";
import NoData from "../../../../components/no-data/no-data";
import { Table } from "../../../../components/table/table";
import ShopService from "../../../../features/shop/service";
import { getOrders, selectOrders } from "../../../../features/shop/slice";
import { Order } from "../../../../features/shop/type";
import ShopOrderStatus from "../../../shop/components/shop-order-status/shop-order-status";
import "./profile-orders.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  order?: Order;
};

const ProfileOrdersContainer = makeContainer("profile-orders-container");
const ProfileOrdersItemSeeDetails = makeSpanContainer(
  "profile-orders-item-see-details"
);

const ProfileOrders: React.FC<Props> = ({ children }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const orders: Order[] = useSelector(selectOrders);
  const hasOrders = orders && orders.length > 0;

  useEffect(() => {
    dispatch(
      getOrders({
        service: new ShopService(),
      })
    );
  }, [dispatch]);

  return (
    <ProfileOrdersContainer>
      {!hasOrders && <NoData noDataText="sem pedidos" />}
      {hasOrders && (
        <Table
          data={orders}
          columns={[
            {
              label: "Pedido",
              style: {
                width: "60%",
              },
            },
            {
              label: "Status",
              style: {
                width: "40%",
              },
            },
          ]}
          cells={[
            ({ row }) => (
              <ProfileOrdersItemSeeDetails>
                <div
                  onClick={() => {
                    navigate(
                      `/shop/${ShopPages.ORDER_DETAILS.replace(":id", row.id)}`
                    );
                  }}
                >
                  {`#${row.id.split("-").slice(-1)[0].toUpperCase()}`}
                </div>
              </ProfileOrdersItemSeeDetails>
            ),
            ({ row }) => (
              <ShopOrderStatus orderStatus={row.status}></ShopOrderStatus>
            ),
          ]}
        />
      )}
    </ProfileOrdersContainer>
  );
};

export default memo(ProfileOrders);
