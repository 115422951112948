import Attribute from "./attribute";
import { DashboardFilterConsts } from "./constants";
import { ActiveFilter } from "./filter";

export enum LevelOfDetail {
  GLOBAL = "GLOBAL",
  STATE = "STATE",
  CITY = "CITY",
  ZONE = "ZONE",
  BURROW = "BURROW",
  PLACE = "PLACE",
}

export class QueryRequestFilter {
  operand: string;
  type: string;
  value: any;
}

export class QueryRequestParameters {
  state: string | null;
  years: string[];
  level: LevelOfDetail;
  level_filter: string | null;
  filters: QueryRequestFilter[];
}

export class QueryRequest {
  attribute: string;
  params: QueryRequestParameters;
  compare_params: QueryRequestParameters;
}

export class QueryCreator {
  attribute: Attribute;
  filters: ActiveFilter[];
  comparativeFilters: ActiveFilter[];
  comparativeMode: boolean;
  zoneMode: boolean;

  constructor(
    attribute: Attribute,
    filters: ActiveFilter[],
    comparativeFilters: ActiveFilter[],
    comparativeMode: boolean,
    zoneMode: boolean
  ) {
    this.attribute = attribute;
    this.filters = filters;
    this.comparativeFilters = comparativeFilters;
    this.comparativeMode = comparativeMode;
    this.zoneMode = zoneMode;
  }

  toRequest(): QueryRequest {
    const requestData = {
      attribute: this.attribute.name,
      params: this.makeRequestParameters(),
      compare_params: this.makeCompareRequestParameters(),
    };

    return requestData;
  }

  makeRequestParameters(): QueryRequestParameters {
    return this.makeParamsFromFilters(this.filters);
  }

  makeCompareRequestParameters(): QueryRequestParameters {
    return this.comparativeMode
      ? this.makeParamsFromFilters(this.comparativeFilters)
      : null;
  }

  makeParamsFromFilters(filters: ActiveFilter[]): QueryRequestParameters {
    const paramsData = {
      state: this.getStateFromFilters(filters),
      years: this.getYearsFromFilters(filters),
      level: this.getLevelFromFilters(filters),
      level_filter: this.getLevelFilterFromFilters(filters),
      filters: this.getRemainingFilters(filters),
    };

    return { ...new QueryRequestParameters(), ...paramsData };
  }

  getStateFromFilters(filters: ActiveFilter[]): string | null {
    const stateFilter = filters.find(
      (f) => f.name.value === DashboardFilterConsts.STATE
    );
    return stateFilter ? stateFilter.value.value : null;
  }

  getYearsFromFilters(filters: ActiveFilter[]): string[] | null {
    const yearsFilters = filters.filter(
      (f) => f.name.value === DashboardFilterConsts.YEAR
    );
    return yearsFilters.length ? yearsFilters.map((f) => f.value.value) : [];
  }

  getLevelFromFilters(filters: ActiveFilter[]): LevelOfDetail | null {
    const stateFilter = DashboardFilterConsts.STATE.toString();
    const cityFilter = DashboardFilterConsts.CITY.toString();
    const burrowFilter = DashboardFilterConsts.BURROW.toString();
    const filterNames = filters.map((f) => f.name.value);

    if (filterNames.includes(burrowFilter)) {
      return LevelOfDetail.PLACE;
    }

    if (filterNames.includes(cityFilter)) {
      return LevelOfDetail.BURROW;
    }

    if (filterNames.includes(stateFilter)) {
      return this.zoneMode ? LevelOfDetail.ZONE : LevelOfDetail.CITY;
    }

    return LevelOfDetail.STATE;
  }

  getLevelFilterFromFilters(filters: ActiveFilter[]): string | null {
    const filterNames = [
      DashboardFilterConsts.BURROW,
      DashboardFilterConsts.CITY,
    ];

    for (const filterName of filterNames) {
      const filter = filters.find((f) => f.name.value === filterName);

      if (filter) {
        return filter.value.value;
      }
    }

    return null;
  }

  getRemainingFilters(filters: ActiveFilter[]): QueryRequestFilter[] | null {
    const remainingFilterNames = [
      DashboardFilterConsts.TURN.toString(),
      DashboardFilterConsts.OFFICE.toString(),
      DashboardFilterConsts.PARTY.toString(),
      DashboardFilterConsts.CANDIDATE.toString(),
      DashboardFilterConsts.SEX.toString(),
      DashboardFilterConsts.RACE.toString(),
      DashboardFilterConsts.SCHOOL_LEVEL.toString(),
      DashboardFilterConsts.ELECTION_STATUS.toString(),
      DashboardFilterConsts.ELECTORATE_MARITAL_STATUS.toString(),
      DashboardFilterConsts.ELECTORATE_SEX.toString(),
      DashboardFilterConsts.ELECTORATE_AGE_GROUP.toString(),
      DashboardFilterConsts.ELECTORATE_SCHOOLING.toString(),
    ];

    const remainingFilters = filters
      .filter((f) => remainingFilterNames.includes(f.name.value))
      .filter((f) => {
        return this.attribute.valid_filters.includes(f.name.value);
      });

    const groupedFilters = remainingFilters.reduce((groupedFilters, filter) => {
      if (!groupedFilters[filter.name.value]) {
        groupedFilters[filter.name.value] = [];
      }
      groupedFilters[filter.name.value].push(filter.value.value);
      return groupedFilters;
    }, {} as QueryRequestFilter);

    const requestFilters = Object.keys(groupedFilters).map((key) => ({
      operand: key,
      type: "in",
      value: groupedFilters[key],
    }));

    return requestFilters;
  }
}
