import React, { memo } from "react";
import { Status } from "../../../../app/constants";
import ShopButton from "../../../../components/buttons/shop-button/shop-button";
import { makeContainer } from "../../../../components/container/container";
import Loading from "../../../../components/loading/loading";
import { Table } from "../../../../components/table/table";
import {
  Candidate,
  CandidateElectionSummary,
} from "../../../../features/candidates/type";
import { CandidateDetailItem } from "../shop-candidature-search-result-item/shop-candidature-search-result-item";
import "./shop-candidature-search-result.css";

type Props = {
  candidate?: Candidate;
  status: Status;
  selectedCandidature: CandidateElectionSummary | null;
  setSelectedCandidature: (CandidateElectionSummary) => void;
};

const ShopCandidatureSearchResultContainer = makeContainer(
  "shop-candidature-search-result"
);

const ShopCandidatureSearchResult: React.FC<Props> = ({
  candidate,
  status,
  selectedCandidature,
  setSelectedCandidature,
}) => {
  const isLoading = status === Status.LOADING;

  const handleClick = (candidature: CandidateElectionSummary) => {
    setSelectedCandidature(candidature);
  };

  const isSelected = (candidature: CandidateElectionSummary) => {
    return selectedCandidature === candidature;
  };

  return (
    <ShopCandidatureSearchResultContainer>
      {isLoading && <Loading />}
      {!isLoading && (
        <Table
          data={candidate?.election_summary ?? []}
          columns={[
            {
              label: "Ano",
              style: {
                width: "20%",
                marginLeft: "10px",
              },
            },
            {
              label: "Local",
              style: {
                width: "30%",
                marginLeft: "5px",
              },
            },
            {
              label: "Cargo",
              style: {
                width: "25%",
                marginLeft: "5px",
              },
            },
            {
              label: "",
              style: {
                width: "20%",
                display: "flex",
                justifyContent: "center",
              },
            },
          ]}
          cells={[
            ({ row }) => (
              <CandidateDetailItem>
                {`${row?.electionyear}(${row?.turn}º)`}
              </CandidateDetailItem>
            ),
            ({ row }) => (
              <CandidateDetailItem>{row?.electionplace}</CandidateDetailItem>
            ),
            ({ row }) => (
              <CandidateDetailItem>{row?.office}</CandidateDetailItem>
            ),
            ({ row }) => (
              <ShopButton
                selected={isSelected(row)}
                onClick={() => handleClick(row)}
              >
                {isSelected(row) ? "Selecionado" : "Selecionar"}
              </ShopButton>
            ),
          ]}
        />

        // <ScrollContainer>
        //   <>
        //     {candidate?.election_summary?.map((summary, index) => (
        //       <ShopCandidatureSearchResultItem
        //         summary={summary}
        //         selected={selectedCandidature}
        //         setSelected={setSelectedCandidature}
        //         key={`candidature-details-${index}`}
        //       />
        //     ))}
        //   </>
        // </ScrollContainer>
      )}
    </ShopCandidatureSearchResultContainer>
  );
};

export default memo(ShopCandidatureSearchResult);
