import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ShopPages, Status } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import ShopActionButton from "../../../components/buttons/shop-action-button/shop-action-button";
import ShopTextButton from "../../../components/buttons/shop-text-button/shop-text-button";
import ShopSearchInput from "../../../components/inputs/shop-search-input/shop-search-input";
import ShopHeading, {
  Variant,
} from "../../../components/text/shop-heading/shop-heading";
import { CandidateSearch } from "../../../features/candidates/type";
import ShopService from "../../../features/shop/service";
import {
  searchCandidates,
  selectCandidates,
  selectSearchCandidatesStatus,
  selectSelectedCandidate,
  setSelectedCandidate,
} from "../../../features/shop/slice";
import ShopCandidateSearchResult from "../components/shop-candidate-search-result/shop-candidate-search-result";
import ShopPageContainer from "../components/shop-page-container/shop-page-container";
import ShopSpacer from "../components/shop-spacer/shop-spacer";

const ReportCandidateSelect: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const status: Status = useSelector(selectSearchCandidatesStatus);
  const candidates: CandidateSearch[] = useSelector(selectCandidates);
  const selectedCandidate = useSelector(selectSelectedCandidate);
  const [sendTimeout, setSendTimeout] = useState(null);

  const dispatchSearchCandidate = (searchText: string) => {
    dispatch(
      searchCandidates({
        search: searchText,
        service: new ShopService(),
      })
    );
  };

  const handleChange = (e) => {
    clearTimeout(sendTimeout);
    setSendTimeout(
      setTimeout(
        () => dispatchSearchCandidate(e.target.value.toUpperCase()),
        800
      )
    );
  };

  return (
    <ShopPageContainer>
      <ShopHeading variant={Variant.H1}>
        Siga nosso passo a passo para escolher o seu Relatório:
      </ShopHeading>
      <ShopSpacer space={10} />

      <ShopHeading variant={Variant.H2}>
        Para começar, busque pelo candidato desejado:
      </ShopHeading>

      <ShopSearchInput
        placeHolder="Busque pelo Nome ou Nome de Urna"
        onChange={handleChange}
      />
      <ShopCandidateSearchResult
        status={status}
        candidates={candidates}
        selectedCandidate={selectedCandidate}
        setSelectedCandidate={(candidateId) =>
          dispatch(setSelectedCandidate(candidateId))
        }
      />
      <ShopSpacer space={10} />
      <ShopActionButton
        disable={selectedCandidate === null}
        outline={false}
        onClick={() => navigate(`/shop/${ShopPages.REPORT_CANDIDATURE}`)}
      >
        Prosseguir para a escolha da candidatura
      </ShopActionButton>
      <ShopSpacer space={20} />
      <ShopTextButton
        onClick={() => navigate(`/shop/${ShopPages.PRODUCT_SELECT}`)}
      >
        {"Cancelar"}
      </ShopTextButton>
    </ShopPageContainer>
  );
};

export default ReportCandidateSelect;
