import {
  setDashboardLoading,
  setLeftWidgetAttribute,
  setMapAttribute,
  setRightWidgetAttribute,
} from "../features/dashboard/slice";
import TargetService from "../features/target/service";
import {
  CompleteTargetDetails,
  CreateTargetRequest,
  GlobalStats,
  TargetDetails,
  UpdateDetails,
  UpdateTargetCityRequest,
  UpdateTargetRootRequest,
} from "../features/target/type";
import { PriorityItem } from "../pages/targets/priorities-selection/components/priorities-selector";
import {
  BELOW_AVERAGE,
  BELOW_AVERAGE_PT,
  BELOW_LEAST_VOTED,
  BELOW_LEAST_VOTED_PT,
  CITY_TARGET,
  LevelOfDetail,
  ON_AVERAGE,
  ON_AVERAGE_PT,
  OPTIMISTIC,
  OPTIMISTIC_PT,
  PRIMARY_TARGET_LEVEL,
  TargetFilterConsts,
  TargetPages,
  TOO_OPTIMISTIC,
  TOO_OPTIMISTIC_PT,
  UNFEASIBLE,
  UNFEASIBLE_PT,
} from "./constants";
import { create, createName, createValue } from "./filter";
import { getRollUpFilter, listToObjKeys } from "./utils";

export const getGlobalTargetAdequacy = (
  initialTarget: string,
  stats: GlobalStats
) => {
  const target = parseInt(initialTarget);

  if (!target) {
    return {
      adequacyLevel: "defina sua meta",
      adequacyColor: "#747475",
    };
  }
  if (target < stats.smallest_voting) {
    return {
      adequacyLevel: "menor que o menos votado",
      adequacyColor: "#D77670",
    };
  } else if (target < stats.avg_votes_among_elected) {
    return {
      adequacyLevel: "abaixo da média",
      adequacyColor: "#D4C95E",
    };
  } else if (
    target <
    stats.avg_votes_among_elected + stats.std_votes_among_elected
  ) {
    return {
      adequacyLevel: "na média",
      adequacyColor: "#6FBD71",
    };
  } else if (target < stats.largest_voting) {
    return {
      adequacyLevel: "otimista",
      adequacyColor: "#6FBD71",
    };
  } else {
    return {
      adequacyLevel: "muito otimista",
      adequacyColor: "#D4C95E",
    };
  }
};

export const getTargetAdequacyLevel = (
  value: number,
  details: TargetDetails
) => {
  if (value < details.avg_votes) {
    return BELOW_AVERAGE;
  } else if (value <= details.avg_votes + details.std_votes) {
    return ON_AVERAGE;
  } else if (value <= details.max_votes) {
    return OPTIMISTIC;
  } else if (value <= details.nominal_votes) {
    return TOO_OPTIMISTIC;
  } else {
    return UNFEASIBLE;
  }
};

export const getTargetAdequacySettings = (adequacyLevel: string) => {
  const targesAdequacySettings = {
    [BELOW_LEAST_VOTED]: {
      adequacyLevel: BELOW_LEAST_VOTED_PT,
      adequacyColor: "#6FBD71",
    },
    [BELOW_AVERAGE]: {
      adequacyLevel: BELOW_AVERAGE_PT,
      adequacyColor: "#6FBD71",
    },
    [ON_AVERAGE]: {
      adequacyLevel: ON_AVERAGE_PT,
      adequacyColor: "#6FBD71",
    },
    [OPTIMISTIC]: {
      adequacyLevel: OPTIMISTIC_PT,
      adequacyColor: "#6FBD71",
    },
    [TOO_OPTIMISTIC]: {
      adequacyLevel: TOO_OPTIMISTIC_PT,
      adequacyColor: "#D4C95E",
    },
    [UNFEASIBLE]: {
      adequacyLevel: UNFEASIBLE_PT,
      adequacyColor: "#D77670",
    },
  };

  return targesAdequacySettings[adequacyLevel];
};

export const findCityPriority = (
  createTargetRequest: CreateTargetRequest,
  priorityCityId: string
) => {
  return createTargetRequest?.city_priorities?.find(
    (p) => p.city_id === priorityCityId
  );
};

export const findOrCreateCityPriority = (
  createTargetRequest: CreateTargetRequest,
  priorityCityId: string
) => {
  let cityPriority = createTargetRequest?.city_priorities?.find(
    (p) => p.city_id === priorityCityId
  );

  if (!cityPriority) {
    cityPriority = {
      city_id: priorityCityId,
      priority_specification: {
        primaries: [],
        secondaries: [],
      },
    };

    createTargetRequest.city_priorities.push(cityPriority);
  }

  return cityPriority;
};

export const addRootPriorities = (createTargetRequest: CreateTargetRequest) => {
  if (!createTargetRequest.priorities) {
    createTargetRequest.priorities = {
      primaries: [],
      secondaries: [],
    };
  }

  return createTargetRequest;
};

export const addCityPrioritiesList = (
  createTargetRequest: CreateTargetRequest
) => {
  if (!createTargetRequest.city_priorities) {
    createTargetRequest.city_priorities = [];
  }

  return createTargetRequest;
};

export const filterAlreadySelected = (
  priorityItems: PriorityItem[],
  priorityLevel: string,
  isRoot: boolean,
  createTargetRequest: CreateTargetRequest
) => {
  const fieldToLook =
    priorityLevel === PRIMARY_TARGET_LEVEL ? "secondaries" : "primaries";
  let selectedIds = [];

  if (isRoot) {
    selectedIds = selectedIds.concat(
      createTargetRequest?.priorities?.[fieldToLook]
    );
  } else {
    if (createTargetRequest?.city_priorities) {
      for (const cityPriority of createTargetRequest?.city_priorities) {
        selectedIds = selectedIds.concat(
          cityPriority.priority_specification[fieldToLook]
        );
      }
    }
  }

  const selectedIdsKey = listToObjKeys(selectedIds);
  //return priorityItems.filter((item) => !(item.id in selectedIdsKey));
  return priorityItems.map((item) => {
    return { ...item, alreadySelected: item.id in selectedIdsKey };
  });
};

export const getOrCreateTargetRootUpdate = (
  updateTargetRootRequest: UpdateTargetRootRequest
) => {
  if (!updateTargetRootRequest) {
    return {
      targets: [],
    };
  }
  return JSON.parse(JSON.stringify(updateTargetRootRequest));
};

export const getOrCreateTargetCityUpdate = (
  city_id: string,
  updateTargetCityRequest: UpdateTargetCityRequest
) => {
  if (!updateTargetCityRequest) {
    return {
      city_id: city_id,
      targets: [],
    };
  }
  return JSON.parse(JSON.stringify(updateTargetCityRequest));
};

export const updateOrReplaceTargetUpdate = (
  value: number,
  id: string,
  updateDetails: UpdateDetails[]
) => {
  const existingUpdate = updateDetails.find((d) => d.id === id);
  if (existingUpdate) {
    existingUpdate.target = value;
  } else {
    updateDetails.push({
      id: id,
      target: value,
    });
  }
};

export const goToTarget = (target, dispatch, setFilters, setCurrentPage) => {
  const service = new TargetService();
  const attributes = service.getAttributesSync();

  const targetFilter = {
    filters: [
      create(
        createName(TargetFilterConsts.TARGET_ID, TargetFilterConsts.TARGET_ID),
        createValue(target.id, target.id)
      ),
      create(
        getRollUpFilter(LevelOfDetail.CITY),
        createValue("", target.state)
      ),
    ],
  };

  if (target.target_type === CITY_TARGET) {
    targetFilter.filters.push(
      create(
        getRollUpFilter(LevelOfDetail.BURROW),
        createValue("", target.city_id)
      )
    );
  }

  dispatch(setMapAttribute(attributes[0]));
  dispatch(setLeftWidgetAttribute(attributes[1]));
  dispatch(setRightWidgetAttribute(attributes[2]));
  dispatch(setFilters(targetFilter));
  dispatch(setCurrentPage(TargetPages.TARGET_DASHBOARD));
  dispatch(setDashboardLoading(null));
};

export const generateCSV = (data: CompleteTargetDetails[]) => {
  const csvContent =
    "data:text/csv;charset=utf-8," +
    encodeURIComponent(
      "\uFEFFLocal,Meta,Prioridade,Adequação,Votos Brancos,Votos Nulos,Média entre eleitos,Eleito com mais votos,Votos Nominais no local\n" +
        data
          .map(
            (d) =>
              `${d.name},${d.value},${d.priority},${d.adequacy_level},${d.blank_votes},${d.null_votes},${d.avg_votes},${d.max_votes},${d.nominal_votes}`
          )
          .join("\n")
    );

  const link = document.createElement("a");
  link.setAttribute("href", csvContent);
  link.setAttribute("download", "data.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
