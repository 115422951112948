import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Attribute from "../../../../app/attribute";
import { CITY_TARGET, LevelOfDetail, Status } from "../../../../app/constants";
import {
  drillDownMap,
  rollUpMap,
  toGeoMapDataItems,
} from "../../../../app/dashboard";
import { Feature, MapFeatures } from "../../../../app/maps";
import { AppDispatch } from "../../../../app/store";
import { makeContainer } from "../../../../components/container/container";
import MapControls from "../../../../components/dashboard/map/controls/map-controls";
import MapViewer from "../../../../components/dashboard/map/viewer/map-viewer";
import { setAlert } from "../../../../features/alert/slice";
import { AlertType } from "../../../../features/alert/type";

import { getCurrentLevelOfDetail } from "../../../../app/utils";
import {
  getAttributes,
  getMapData,
  selectAttributes,
  selectDashboardFilters,
  selectFullScreenMode,
  selectMapAttribute,
  selectMapData,
  selectMapDataStatus,
  selectMapFeatures,
  selectSelectedFeatures,
  setFullScreenMode,
  setMapAttribute,
  setMapFeatures,
  setSelectedFeatures,
} from "../../../../features/dashboard/slice";
import { QueryResult } from "../../../../features/dashboard/types";
import MapsService from "../../../../features/maps/service";
import {
  getMaps,
  selectMaps,
  selectMapsStatus,
} from "../../../../features/maps/slice";
import { GeoProperties, RawGeoMap } from "../../../../features/maps/types";
import PermissionService from "../../../../features/permission/service";
import {
  getPermissions,
  selectPermissions,
} from "../../../../features/permission/slice";
import { PermissionsData } from "../../../../features/permission/type";
import TargetService from "../../../../features/target/service";
import {
  selectTargetRoot,
  selectTargetUpdateCounter,
  selectUpdateTargetStatus,
} from "../../../../features/target/slice";
import { TargetRootResponse } from "../../../../features/target/type";

type ContainerProps = {
  children?: string | JSX.Element | JSX.Element[];
  fullScreenMode: boolean;
};

const DashMapContainer = makeContainer("dashboard-target-map-container");
const FullScreenDashMapContainer = makeContainer(
  "dashboard-target-fullscreen-map-container"
);

const Container: React.FC<ContainerProps> = ({ fullScreenMode, children }) => {
  return (
    <>
      {fullScreenMode && (
        <FullScreenDashMapContainer>{children}</FullScreenDashMapContainer>
      )}
      {!fullScreenMode && <DashMapContainer>{children}</DashMapContainer>}
    </>
  );
};

const getStatus = (
  rawMapsStatus: Status,
  mapDataStatus: Status,
  updateTargetStatus: Status
) => {
  const statusList = [rawMapsStatus, mapDataStatus, updateTargetStatus];

  if (statusList.includes(Status.FAILED)) {
    return Status.FAILED;
  }

  if (statusList.includes(Status.LOADING)) {
    return Status.LOADING;
  }

  return Status.SUCCEEDED;
};

const getFirstValidAttribute = (attributes: Attribute[]) => {
  return attributes[0];
};

const MapWithData: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  // Selectors
  const rawMaps: RawGeoMap<GeoProperties> = useSelector(selectMaps);
  const rawMapsStatus: Status = useSelector(selectMapsStatus);
  const mapData: QueryResult = useSelector(selectMapData);
  const mapDataStatus: Status = useSelector(selectMapDataStatus);
  const updateTargetStatus: Status = useSelector(selectUpdateTargetStatus);
  const mapAttribute: Attribute = useSelector(selectMapAttribute);
  const attributes: Attribute[] = useSelector(selectAttributes);
  const dashFilters = useSelector(selectDashboardFilters);
  const permissions: PermissionsData = useSelector(selectPermissions);
  const targetUpdateCounter: number = useSelector(selectTargetUpdateCounter);
  const fullScreenMode: boolean = useSelector(selectFullScreenMode);
  const selectedFeatures: Feature[] = useSelector(selectSelectedFeatures);
  const map: MapFeatures = useSelector(selectMapFeatures);
  const target: TargetRootResponse = useSelector(selectTargetRoot);

  // Estado do mapa
  const status = getStatus(rawMapsStatus, mapDataStatus, updateTargetStatus);
  const [triggerCenterMap, setTriggerCenterMap] = useState(0);
  const [triggerZoomIn, setTriggerZoomIn] = useState(0);
  const [triggerZoomOut, setTriggerZoomOut] = useState(0);

  useEffect(() => {
    const doInitialSetUp = () => {
      dispatch(getAttributes({ service: new TargetService() }));
      dispatch(getPermissions(new PermissionService()));
    };

    doInitialSetUp();
  }, [dispatch]);

  useEffect(() => {
    const setInitialMapAttribute = () => {
      if (attributes) {
        const newAttribute = getFirstValidAttribute(attributes);
        if (newAttribute) {
          dispatch(setMapAttribute(newAttribute));
        }
      }
    };

    const cleanupAttribute = () => {
      dispatch(setMapAttribute(null));
    };

    setInitialMapAttribute();
    return cleanupAttribute;
  }, [dispatch, attributes]);

  useEffect(() => {
    const fetchData = () => {
      if (mapAttribute) {
        dispatch(
          getMapData({
            attribute: mapAttribute,
            filters: dashFilters,
            comparativeFilters: [],
            comparativeMode: false,
            zoneMode: false,
            service: new TargetService(),
          })
        );

        dispatch(
          getMaps({
            filters: dashFilters,
            zoneMode: false,
            service: new MapsService(),
          })
        );
      }
    };

    fetchData();
  }, [dispatch, dashFilters, mapAttribute, targetUpdateCounter]);

  useEffect(() => {
    const mergeMapAndData = () => {
      const finishedLoading =
        rawMapsStatus === Status.SUCCEEDED &&
        mapDataStatus === Status.SUCCEEDED;
      const hasData = rawMaps && mapData;

      if (finishedLoading && hasData) {
        const [items, envelope] = toGeoMapDataItems(
          false,
          rawMaps,
          mapData,
          mapAttribute
        );
        dispatch(setMapFeatures({ features: items, envelope }));
      }
    };

    mergeMapAndData();
  }, [dispatch, rawMapsStatus, rawMaps, mapDataStatus, mapData, mapAttribute]);

  useEffect(() => {
    const clearMapSelection = () => {
      dispatch(setSelectedFeatures(null));
    };

    clearMapSelection();
  }, [dispatch, dashFilters]);

  const handleFeatureSelected = useCallback(
    (selected) => {
      const feature = map?.features?.find((f) => f.id === selected.value);
      dispatch(setSelectedFeatures([feature]));
    },
    [dispatch, map]
  );

  const handleFeatureClick = useCallback(
    (feature: Feature) => {
      dispatch(setSelectedFeatures([feature]));
    },
    [dispatch]
  );

  const handleFeatureDblClick = useCallback(
    (feature: Feature) => {
      const level = getCurrentLevelOfDetail(dashFilters, false);
      if (target?.target_type === CITY_TARGET || level !== LevelOfDetail.CITY) {
        dispatch(
          setAlert({
            message: "Não é possível detalhar mais",
            type: AlertType.WARNING,
          })
        );
        return;
      }

      drillDownMap(dispatch, permissions, feature, dashFilters, [], false);
    },
    [dispatch, target, permissions, dashFilters]
  );

  const handleCenterMapBtnClick = useCallback(() => {
    setTriggerCenterMap((t) => t + 1);
  }, []);

  const handleZoomInBtnClick = useCallback(() => {
    setTriggerZoomIn((t) => t + 1);
  }, []);

  const handleZoomOutBtnClick = useCallback(() => {
    setTriggerZoomOut((t) => t + 1);
  }, []);

  const handleFullScreenBtnClick = useCallback(() => {
    dispatch(setFullScreenMode(!fullScreenMode));
  }, [dispatch, fullScreenMode]);

  const handleRollUpBtnClick = useCallback(() => {
    const level = getCurrentLevelOfDetail(dashFilters, false);
    if (target?.target_type === CITY_TARGET || level !== LevelOfDetail.BURROW) {
      dispatch(
        setAlert({
          message: "Você está no nível mais alto",
          type: AlertType.WARNING,
        })
      );
      return;
    }

    rollUpMap(dispatch, dashFilters, [], false);
  }, [dispatch, target, dashFilters]);

  const handleDrillDownBtnClick = useCallback(() => {
    if (!selectedFeatures || !selectedFeatures.length) {
      dispatch(
        setAlert({
          message: "Selecione um local no mapa",
          type: AlertType.WARNING,
        })
      );
      return;
    }

    const level = getCurrentLevelOfDetail(dashFilters, false);
    if (target?.target_type === CITY_TARGET || level !== LevelOfDetail.CITY) {
      dispatch(
        setAlert({
          message: "Não é possível detalhar mais",
          type: AlertType.WARNING,
        })
      );
      return;
    }

    const feature = selectedFeatures[0];
    drillDownMap(dispatch, permissions, feature, dashFilters, [], false);
  }, [dispatch, target, permissions, selectedFeatures, dashFilters]);

  const handleAttributeSelected = useCallback(
    (att: Attribute) => {
      dispatch(setMapAttribute(att));
    },
    [dispatch]
  );

  return (
    <Container fullScreenMode={fullScreenMode}>
      <MapControls
        map={map}
        onCenterMapBtnClick={handleCenterMapBtnClick}
        onZoomInBtnClick={handleZoomInBtnClick}
        onZoomOutBtnClick={handleZoomOutBtnClick}
        onComparativeModeBtnClick={null}
        onZoneCityToggleBtnClick={null}
        onFullScreenBtnClick={handleFullScreenBtnClick}
        onRollUpBtnClick={handleRollUpBtnClick}
        onDrillDownBtnClick={handleDrillDownBtnClick}
        onAttributeSelected={handleAttributeSelected}
        handleFeatureSelected={handleFeatureSelected}
      />
      <MapViewer
        map={map}
        status={status}
        triggerCenterMap={triggerCenterMap}
        triggerZoomIn={triggerZoomIn}
        triggerZoomOut={triggerZoomOut}
        handleFeatureClick={handleFeatureClick}
        handleFeatureDblClick={handleFeatureDblClick}
      />
    </Container>
  );
};

export default memo(MapWithData);
