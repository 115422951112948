import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../app/constants";
import { ActiveFilter } from "../../app/filter";
import { debounceThunk } from "../../app/utils";
import {
  GeoProperties,
  IMapsService,
  RawGeoMap,
  TargetBurrow,
  TargetCity,
} from "./types";

const maps: RawGeoMap<GeoProperties> | null = null;
const targetCities: TargetCity[] = [];
const targetBurrows: TargetBurrow[] = [];

// Slice's initial state
const initialState = {
  status: Status.LOADING,
  maps: maps,
  targetCities: targetCities,
  targetBurrows: targetBurrows,
  error: "",
};

type GetStateMapsParams = {
  filters: ActiveFilter[];
  zoneMode: boolean;
  service: IMapsService;
};

type GetCitiesParams = {
  stateId: string;
  service: IMapsService;
};

type GetBurrowsParams = {
  stateId: string;
  cityId: string;
  service: IMapsService;
};

// get state map thunk
const getMapsThunk = createAsyncThunk<
  RawGeoMap<GeoProperties>,
  GetStateMapsParams
>("maps/getmaps", async (params) => {
  const { filters, zoneMode, service } = params;
  const response = await service.getMaps(filters, zoneMode);
  return response;
});

const getCitiesThunk = createAsyncThunk<TargetCity[], GetCitiesParams>(
  "maps/getcities",
  async (params) => {
    const { stateId, service } = params;
    const response = await service.getTargetCities(stateId);
    return response;
  }
);

const getBurrowsThunk = createAsyncThunk<TargetBurrow[], GetBurrowsParams>(
  "maps/getburrows",
  async (params) => {
    const { stateId, cityId, service } = params;
    const response = await service.getTargetBurrows(stateId, cityId);
    return response;
  }
);

// Selectors
export const selectMapsStatus = (state) => state.maps.status;
export const selectMaps = (state) => state.maps.maps;
export const selectTargetCities = (state) => state.maps.targetCities;
export const selectTargetBurrows = (state) => state.maps.targetBurrows;

// Reducers
const loading = (state, action) => {
  state.status = Status.LOADING;
};

const successfulMaps = (state, action) => {
  state.status = Status.SUCCEEDED;
  state.maps = action.payload;
  state.error = "";
};

const successfulTargetCities = (state, action) => {
  state.status = Status.SUCCEEDED;
  state.targetCities = action.payload;
  state.error = "";
};

const successfulTargetBurrows = (state, action) => {
  state.status = Status.SUCCEEDED;
  state.targetBurrows = action.payload;
  state.error = "";
};

const rejected = (state, action) => {
  state.status = Status.FAILED;
  state.error = action.error.message;
};

// Slice
const mapsSlice = createSlice({
  name: "maps",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.status = Status.LOADING;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getMapsThunk.pending, loading)
      .addCase(getMapsThunk.fulfilled, successfulMaps)
      .addCase(getMapsThunk.rejected, rejected)
      .addCase(getCitiesThunk.pending, loading)
      .addCase(getCitiesThunk.fulfilled, successfulTargetCities)
      .addCase(getCitiesThunk.rejected, rejected)
      .addCase(getBurrowsThunk.pending, loading)
      .addCase(getBurrowsThunk.fulfilled, successfulTargetBurrows)
      .addCase(getBurrowsThunk.rejected, rejected);
  },
});
export const { setLoading } = mapsSlice.actions;
export const getMaps = debounceThunk(getMapsThunk);
export const getCities = debounceThunk(getCitiesThunk);
export const getBurrows = debounceThunk(getBurrowsThunk);
export default mapsSlice.reducer;
