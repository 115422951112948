import React, { useEffect, useState } from "react";
import { Status } from "../../../app/constants";

interface LoadingTextProps {
  children: string;
  status: Status;
}

const LoadingText: React.FC<LoadingTextProps> = ({ children, status }) => {
  const [dots, setDots] = useState<string>("");

  useEffect(() => {
    if (status === Status.LOADING) {
      const interval = setInterval(() => {
        setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
      }, 300);

      return () => clearInterval(interval);
    } else {
      setDots("");
    }
  }, [status]);

  return (
    <div>
      {children}
      {dots}
    </div>
  );
};

export default LoadingText;
