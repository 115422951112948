import React, { memo, useRef, useState } from "react";
import Attribute, { AttributeTypes } from "../../../app/attribute";
import { Status } from "../../../app/constants";
import { GroupAttributeData } from "../../../features/dashboard/types";
import SelectAttributeButton from "../../buttons/select-attribute-button/select-attribute-button";
import { makeContainer } from "../../container/container";
import Loading from "../../loading/loading";
import PopMenu from "../../menu/pop-menu";
import NoData from "../../no-data/no-data";
import ScrollContainer from "../../scroll/scroll";
import WidgetTitleText from "../../text/widget-title-text/widget-title-text";
import WidgetItem from "../widget-item/widget-item";
import "./widget.css";

type Props = {
  attribute: Attribute;
  attributes: Attribute[];
  data: GroupAttributeData[];
  status: Status;
  onAttributeSelected: (att: Attribute) => void;
};

const WidgetContainer = makeContainer("widget-container");
const WidgetTitleContainer = makeContainer("widget-title-container");
const WidgetDataContainer = makeContainer("widget-data-container");

const Widget: React.FC<Props> = ({
  attribute,
  attributes,
  data,
  status,
  onAttributeSelected,
}) => {
  const [menuOpened, setMenuOpenened] = useState(false);
  const ref = useRef();
  const isLoading = status === Status.LOADING;
  const failed = status === Status.FAILED;
  const succeded = status === Status.SUCCEEDED;

  return (
    <WidgetContainer>
      {isLoading && <Loading />}
      {failed && <NoData />}
      {succeded && (
        <>
          <WidgetTitleContainer>
            <WidgetTitleText
              tooltipTitle={attribute?.friendly_name}
              tooltipDescription={attribute?.description}
            >
              {attribute?.friendly_name}
            </WidgetTitleText>
            <PopMenu
              open={menuOpened}
              searchable={true}
              entries={attributes
                ?.filter(
                  (att) =>
                    att.type === AttributeTypes.GROUP &&
                    att.name !== attribute?.name
                )
                .map((att) => ({
                  label: att.friendly_name,
                  key: "",
                  onClick: () => onAttributeSelected(att),
                }))}
              containerRef={ref}
              onClose={() => setMenuOpenened(false)}
            >
              <SelectAttributeButton
                ref={ref}
                onClick={() => setMenuOpenened(true)}
              />
            </PopMenu>
          </WidgetTitleContainer>
          <WidgetDataContainer>
            <ScrollContainer>
              {data?.map((group, index) => (
                <WidgetItem key={`widget-item-${index}`} group={group} />
              ))}
            </ScrollContainer>
          </WidgetDataContainer>
        </>
      )}
    </WidgetContainer>
  );
};

export default memo(Widget);
