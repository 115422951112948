import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "simplebar-react/dist/simplebar.min.css";
import {
  AUTH_PAGE,
  MAIN_PAGE,
  NOTEBOOK_PAGE,
  Pages,
  SHOP_PAGE,
} from "./app/constants";
import AuthPage from "./components/auth-page/page";
import { MainPage } from "./components/main-page/page";
import NotebookPage from "./components/notebook-page/page";
import ShopPage from "./components/shop-page/page";
import { selectIsLogged } from "./features/auth/slice";
import PageNotFound from "./pages/404/not-found";
import Terms from "./pages/terms/terms";

export const App = () => {
  const isLogged = useSelector(selectIsLogged);

  return (
    <BrowserRouter>
      <Routes>
        {/* Página de Autenticação */}
        {!isLogged && <Route path={`${AUTH_PAGE}/*`} element={<AuthPage />} />}
        {/* Páginas principais */}
        {isLogged && <Route path={`${MAIN_PAGE}/*`} element={<MainPage />} />}
        {/* Páginas da loja */}
        <Route path={`${SHOP_PAGE}/*`} element={<ShopPage />} />
        {/* Páginas do notebook */}
        {isLogged && (
          <Route path={`${NOTEBOOK_PAGE}/*`} element={<NotebookPage />} />
        )}
        {/* Termos de Uso */}
        <Route path={Pages.TERMS} element={<Terms />} />
        {/* Para redirecionar a home após o login */}
        {isLogged && (
          <Route
            path={`${AUTH_PAGE}/*`}
            element={<Navigate to={`/${Pages.HOME}`} />}
          />
        )}
        {isLogged && (
          <Route
            path={`${AUTH_PAGE}/${SHOP_PAGE}/*`}
            element={<Navigate to={`/${Pages.SHOP}`} />}
          />
        )}
        {/* Se logado, redirecionar a rota / para a home da plataforma */}
        {isLogged && (
          <Route path={"/"} element={<Navigate to={`/${Pages.HOME}`} />} />
        )}
        {/* Se logado, redirecionar a rota / para a home da plataforma */}
        {isLogged && <Route path={"*"} element={<PageNotFound />} />}
        {/* Se não logado, redirecionar qualquer rota não definida para a página de login */}
        {!isLogged && (
          <Route path={"*"} element={<Navigate to={`/${Pages.LOGIN}`} />} />
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
