import { memo, MouseEventHandler } from "react";
import "./shop-action-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: string | JSX.Element | JSX.Element[];
  outline?: boolean;
  disable?: boolean;
};

const ShopActionButton: React.FC<Props> = (props) => {
  const { onClick, children, outline, disable } = props;

  return (
    <button
      className={
        disable
          ? "shop-action-button-disable"
          : outline
          ? "shop-action-button-outline"
          : "shop-action-button"
      }
      onClick={disable ? () => null : onClick}
    >
      {children}
    </button>
  );
};

export default memo(ShopActionButton);
