import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  NotebookPages,
  NOTEBOOK_PAGE,
  Status,
} from "../../../../app/constants";
import store from "../../../../app/store";
import Button from "../../../../components/buttons/profile-button/profile-button";
import { makeContainer } from "../../../../components/container/container";
import ProfileInput from "../../../../components/inputs/profile-input/profile-input";
import ProfileFormText from "../../../../components/text/profile-form-text/profile-form-text";
import AuthService from "../../../../features/auth/service";
import { UserData } from "../../../../features/auth/type";
import Sillouete from "../../../../resources/icons/user-silhouette.svg";
import "./profile-profile.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  userData?: UserData;
  setPasswordChangeMode?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfileProfileContainer = makeContainer("profile-profile-container");
const ProfileProfileSubContainer = makeContainer(
  "profile-profile-sub-container"
);
const ProfileProfileButtons = makeContainer("profile-profile-buttons");
const ImagePane = makeContainer("profile-profile-image-pane");
const DetailsPane = makeContainer("profile-profile-details-pane");

const ProfileForm: React.FC<Props> = ({ userData, setPasswordChangeMode }) => {
  const navigate = useNavigate();

  return (
    <>
      <ProfileFormText>Nome</ProfileFormText>
      <ProfileInput value={userData?.name} disabled />
      <ProfileFormText>Email</ProfileFormText>
      <ProfileInput value={userData?.email} disabled />
      <ProfileProfileButtons>
        <Button onClick={() => setPasswordChangeMode(true)}>
          Alterar Senha
        </Button>
        <Button
          onClick={() =>
            navigate(`/${NOTEBOOK_PAGE}/${NotebookPages.NOTEBOOK_VIEW}`)
          }
        >
          Ver Caderno
        </Button>
      </ProfileProfileButtons>
    </>
  );
};

const ChangePasswordForm: React.FC<Props> = ({ setPasswordChangeMode }) => {
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [status, setStatus] = useState(Status.IDLE);

  const changePassword = async () => {
    const service = new AuthService();
    setStatus(Status.LOADING);
    service
      .updatePassword(password, passwordRepeat, store)
      .then(() => {
        setStatus(Status.SUCCEEDED);
        setPasswordChangeMode(false);
      })
      .catch(() => setStatus(Status.FAILED));
  };

  return (
    <>
      <ProfileFormText>Nova Senha</ProfileFormText>
      <ProfileInput
        placeHolder="Digite sua nova senha"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <ProfileFormText>Repetir Nova Senha</ProfileFormText>
      <ProfileInput
        placeHolder="Repita a nova senha"
        type="password"
        value={passwordRepeat}
        onChange={(e) => setPasswordRepeat(e.target.value)}
      />
      <ProfileProfileButtons>
        <Button onClick={changePassword} status={status}>
          Alterar Senha
        </Button>
        <Button onClick={() => setPasswordChangeMode(false)}>Cancelar</Button>
      </ProfileProfileButtons>
    </>
  );
};

const ProfileProfile: React.FC<Props> = ({ userData }) => {
  const [passwordChangeMode, setPasswordChangeMode] = useState(false);

  return (
    <ProfileProfileContainer>
      <ProfileProfileSubContainer>
        <ImagePane>
          <img
            className="profile-profile-image"
            alt="imagem do usuário"
            src={userData?.photoUrl ? userData?.photoUrl : Sillouete}
          />
        </ImagePane>
        <DetailsPane>
          {passwordChangeMode ? (
            <ChangePasswordForm
              userData={userData}
              setPasswordChangeMode={setPasswordChangeMode}
            />
          ) : (
            <ProfileForm
              userData={userData}
              setPasswordChangeMode={setPasswordChangeMode}
            />
          )}
        </DetailsPane>
      </ProfileProfileSubContainer>
    </ProfileProfileContainer>
  );
};

export default ProfileProfile;
