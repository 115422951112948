import React, { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Pages } from "../../../../../app/constants";
import SeeMoreButton from "../../../../../components/buttons/see-more-button/see-more-button";
import { makeContainer } from "../../../../../components/container/container";
import CandidateService from "../../../../../features/candidates/service";
import { CandidateSearch } from "../../../../../features/candidates/type";
import Sillouete from "../../../../../resources/icons/user-silhouette.svg";
import "./candidate-search-item.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  candidate?: CandidateSearch;
  compactMode?: boolean;
};

const CandidateSearchItemContainer = makeContainer(
  "candidate-search-item-container"
);

export const CandidateSearchItemDetails: React.FC<Props> = ({
  candidate,
  compactMode,
}) => {
  const service = new CandidateService();
  const [candidatePhoto, setCandidatePhoto] = useState(Sillouete);

  const fetchPhoto = async () => {
    const photo = await service.getCandidatePhoto(candidate.id);
    if (photo) {
      setCandidatePhoto(`data:image/png;base64,${photo}`);
    }
  };

  useEffect(() => {
    fetchPhoto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const containerStyle = !compactMode ? {} : { flexGrow: 1 };
  const photoStyle = !compactMode
    ? {}
    : { width: "22px", height: "22px", minWidth: "22px", minHeight: "22px" };

  return (
    <div className="candidate-search-item-candidate" style={containerStyle}>
      <img
        alt={"foto do candidato"}
        className="candidate-search-item-photo"
        src={candidatePhoto}
        style={photoStyle}
      ></img>
      <div className="candidate-search-item-details">
        <div className="candidate-search-item-name">
          {candidate.ballotbox_name}
        </div>
        <div className="candidate-search-item-candidature-details">
          {candidate.name}
        </div>
      </div>
    </div>
  );
};

const CandidateSearchItemElections: React.FC<Props> = ({ candidate }) => {
  return (
    <div className="candidate-search-item-elections">
      <div className="candidate-search-item-elections-content">
        {candidate.elections}
      </div>
    </div>
  );
};

const CandidateSearchItemWins: React.FC<Props> = ({ candidate }) => {
  return (
    <div className="candidate-search-item-wins">
      <div className="candidate-search-item-wins-content">{candidate.wins}</div>
    </div>
  );
};

export const CandidateSearchAction: React.FC<Props> = ({ candidate }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isMapOpener = queryParams.get("map");

  function handleClick() {
    if (isMapOpener) {
      navigate(
        `/${Pages.VOTING}?candidateId=${candidate.id}&candidate=${candidate.ballotbox_name}&year=${candidate.last_election_year}`
      );
    } else {
      navigate(`/${Pages.CANDIDATE_PROFILE.replace(":id", candidate.id)}`);
    }
  }

  return (
    <div className="candidate-search-item-action">
      <SeeMoreButton onMap={isMapOpener !== null} onClick={handleClick} />
    </div>
  );
};

const CandidateSearchItem: React.FC<Props> = ({ candidate }) => {
  return (
    <CandidateSearchItemContainer>
      <CandidateSearchItemDetails candidate={candidate} />
      <CandidateSearchItemElections candidate={candidate} />
      <CandidateSearchItemWins candidate={candidate} />
      <CandidateSearchAction candidate={candidate} />
    </CandidateSearchItemContainer>
  );
};

export default memo(CandidateSearchItem);
