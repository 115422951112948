import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Attribute from "../../app/attribute";
import { Status } from "../../app/constants";
import { ActiveFilter } from "../../app/filter";
import { Feature, MapFeatures } from "../../app/maps";
import { debounceThunk } from "../../app/utils";
import { IDashboardService, QueryResult } from "./types";

const attributeData: QueryResult | null = null;
const filtersData: ActiveFilter[] = [];
const attribute: Attribute | null = null;
const attributeList: Attribute[] = [];
const features: Feature[] | null = null;
const mapFeatures: MapFeatures | null = null;

// Slice's initial state
const initialState = {
  startupState: null,
  filters: filtersData,
  comparativeFilters: filtersData,
  leftNumberWidgetStatus: Status.LOADING,
  rightNumberWidgetStatus: Status.LOADING,
  widgetStatus: Status.LOADING,
  mapStatus: Status.LOADING,
  leftNumberWidgetData: attributeData,
  rightNumberWidgetData: attributeData,
  widgetData: attributeData,
  mapData: attributeData,
  mapFeatures: mapFeatures,
  attributes: attributeList,
  mapAttribute: attribute,
  widgetAttribute: attribute,
  leftNumberWidgetAttribute: attribute,
  rightNumberWidgetAttribute: attribute,
  selectedFeatures: features,
  comparativeMode: false,
  zoneMode: false,
  fullScreenMode: false,
  error: "",
};

type GetDataParams = {
  attribute: Attribute;
  filters: ActiveFilter[];
  comparativeFilters: ActiveFilter[];
  comparativeMode: boolean;
  zoneMode: boolean;
  service: IDashboardService;
};

type GetAttributesParams = {
  service: IDashboardService;
};

// thunk
const getMapDataThunk = createAsyncThunk<QueryResult, GetDataParams>(
  "dashboard/getmapdata",
  async (params) => {
    const {
      attribute,
      filters,
      comparativeFilters,
      comparativeMode,
      zoneMode,
      service,
    } = params;

    const response = await service.getData(
      attribute,
      filters,
      comparativeFilters,
      comparativeMode,
      zoneMode
    );

    return response;
  }
);

const getAttributesThunk = createAsyncThunk<Attribute[], GetAttributesParams>(
  "dashboard/getmapattributes",
  async (params) => {
    const { service } = params;
    const response = await service.getAttributes();
    return response;
  }
);

const getWidgetDataThunk = createAsyncThunk<QueryResult, GetDataParams>(
  "dashboard/getwidgetdata",
  async (params) => {
    const {
      attribute,
      filters,
      comparativeFilters,
      comparativeMode,
      zoneMode,
      service,
    } = params;

    const response = await service.getData(
      attribute,
      filters,
      comparativeFilters,
      comparativeMode,
      zoneMode
    );

    return response;
  }
);

const getLeftNumberWidgetDataThunk = createAsyncThunk<
  QueryResult,
  GetDataParams
>("dashboard/getleftnumberwidgetdata", async (params) => {
  const {
    attribute,
    filters,
    comparativeFilters,
    comparativeMode,
    zoneMode,
    service,
  } = params;

  const response = service.getData(
    attribute,
    filters,
    comparativeFilters,
    comparativeMode,
    zoneMode
  );

  return response;
});

const getRightNumberWidgetDataThunk = createAsyncThunk<
  QueryResult,
  GetDataParams
>("dashboard/getrightnumberwidgetdata", async (params) => {
  const {
    attribute,
    filters,
    comparativeFilters,
    comparativeMode,
    zoneMode,
    service,
  } = params;

  const response = await service.getData(
    attribute,
    filters,
    comparativeFilters,
    comparativeMode,
    zoneMode
  );

  return response;
});

// Selectors
export const selectDashboardFilters = (state) => state.dashboard.filters;
export const selectDashboardComparativeFilters = (state) =>
  state.dashboard.comparativeFilters;
export const selectMapDataStatus = (state) => state.dashboard.mapStatus;
export const selectMapData = (state) => state.dashboard.mapData;
export const selectWidgetDataStatus = (state) => state.dashboard.widgetStatus;
export const selectWidgetData = (state) => state.dashboard.widgetData;
export const selectLeftWidgetDataStatus = (state) =>
  state.dashboard.leftNumberWidgetStatus;
export const selectLeftWidgetData = (state) =>
  state.dashboard.leftNumberWidgetData;
export const selectRightWidgetDataStatus = (state) =>
  state.dashboard.rightNumberWidgetStatus;
export const selectRightWidgetData = (state) =>
  state.dashboard.rightNumberWidgetData;
export const selectAttributes = (state) => state.dashboard.attributes;
export const selectMapAttribute = (state) => state.dashboard.mapAttribute;
export const selectWidgetAttribute = (state) => state.dashboard.widgetAttribute;
export const selectLeftNumberWidgetAttribute = (state) =>
  state.dashboard.leftNumberWidgetAttribute;
export const selectRightNumberWidgetAttribute = (state) =>
  state.dashboard.rightNumberWidgetAttribute;
export const selectSelectedFeatures = (state) =>
  state.dashboard.selectedFeatures;
export const selectComparativeMode = (state) => state.dashboard.comparativeMode;
export const selectZoneMode = (state) => state.dashboard.zoneMode;
export const selectFullScreenMode = (state) => state.dashboard.fullScreenMode;
export const selectMapFeatures = (state) => state.dashboard.mapFeatures;
export const selectStartupState = (state) => state.dashboard.startupState;

// Reducers
const loadingMapsData = (state, action) => {
  state.mapStatus = Status.LOADING;
};

const successfulMapsData = (state, action) => {
  state.mapStatus = Status.SUCCEEDED;
  state.mapData = action.payload;
  state.error = "";
};

const successfulAttributes = (state, action) => {
  state.attributes = action.payload;
  state.error = "";
};

const rejectedAttributes = (state, action) => {
  state.mapStatus = Status.FAILED;
  state.widgetStatus = Status.FAILED;
  state.leftNumberWidgetStatus = Status.FAILED;
  state.rightNumberWidgetStatus = Status.FAILED;
  state.error = action.error.message;
};

const rejectedMapsData = (state, action) => {
  state.mapStatus = Status.FAILED;
  state.error = action.error.message;
};

const loadingWidgetData = (state, action) => {
  state.widgetStatus = Status.LOADING;
};

const successfulWidgetData = (state, action) => {
  state.widgetStatus = Status.SUCCEEDED;
  state.widgetData = action.payload;
  state.error = "";
};

const rejectedWidgetData = (state, action) => {
  state.widgetStatus = Status.FAILED;
  state.error = action.error.message;
};

const loadingLeftNumberWidgetData = (state, action) => {
  state.leftNumberWidgetStatus = Status.LOADING;
};

const successfulLeftNumberWidgetData = (state, action) => {
  state.leftNumberWidgetStatus = Status.SUCCEEDED;
  state.leftNumberWidgetData = action.payload;
  state.error = "";
};

const rejectedLeftNumberWidgetData = (state, action) => {
  state.leftNumberWidgetStatus = Status.FAILED;
  state.error = action.error.message;
};

const loadingRightNumberWidgetData = (state, action) => {
  state.rightNumberWidgetStatus = Status.LOADING;
};

const successfulRightNumberWidgetData = (state, action) => {
  state.rightNumberWidgetStatus = Status.SUCCEEDED;
  state.rightNumberWidgetData = action.payload;
  state.error = "";
};

const rejectedRightNumberWidgetData = (state, action) => {
  state.rightNumberWidgetStatus = Status.FAILED;
  state.error = action.error.message;
};

// Slice
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    applyStartupState(state, action) {
      for (const key of Object.keys(initialState)) {
        if (key !== "startupState") {
          state[key] = initialState[key];
        }
      }

      const startupState = JSON.parse(JSON.stringify(state.startupState));
      if (startupState) {
        for (const key of Object.keys(startupState)) {
          state[key] = startupState[key];
        }
      }
    },
    setStartupState(state, action) {
      state.startupState = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload.filters;
      state.comparativeFilters = action.payload.comparativeFilters;
    },
    addFilters(state, action) {
      const deduplicatedFilters = [];
      const filtersKey = {};
      const currentFilters = JSON.parse(JSON.stringify(state.filters));
      const newFilters = [...currentFilters, ...action.payload.filters];

      for (const filter of newFilters) {
        const key = `${filter.name.value}:${filter.value.value}`;
        if (key in filtersKey) {
          continue;
        }
        filtersKey[key] = true;
        deduplicatedFilters.push(filter);
      }
      state.filters = deduplicatedFilters;
    },
    setMapAttribute(state, action) {
      state.mapAttribute = action.payload;
    },
    setWidgetAttribute(state, action) {
      state.widgetAttribute = action.payload;
    },
    setLeftWidgetAttribute(state, action) {
      state.leftNumberWidgetAttribute = action.payload;
    },
    setWidgetData(state, action) {
      state.widgetStatus = Status.SUCCEEDED;
      state.widgetData = action.payload;
    },
    setRightWidgetAttribute(state, action) {
      state.rightNumberWidgetAttribute = action.payload;
    },
    setSelectedFeatures(state, action) {
      state.selectedFeatures = action.payload;
    },
    setComparativeMode(state, action) {
      state.comparativeMode = action.payload;
    },
    setZoneMode(state, action) {
      state.zoneMode = action.payload;
    },
    setFullScreenMode(state, action) {
      state.fullScreenMode = action.payload;
    },
    setMapFeatures(state, action) {
      state.mapFeatures = action.payload;
    },
    setDashboardLoading(state, action) {
      state.mapStatus = Status.LOADING;
      state.widgetStatus = Status.LOADING;
      state.leftNumberWidgetStatus = Status.LOADING;
      state.rightNumberWidgetStatus = Status.LOADING;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getMapDataThunk.pending, loadingMapsData)
      .addCase(getMapDataThunk.fulfilled, successfulMapsData)
      .addCase(getMapDataThunk.rejected, rejectedMapsData)
      .addCase(getAttributesThunk.pending, loadingMapsData)
      .addCase(getAttributesThunk.fulfilled, successfulAttributes)
      .addCase(getAttributesThunk.rejected, rejectedAttributes)
      .addCase(getWidgetDataThunk.pending, loadingWidgetData)
      .addCase(getWidgetDataThunk.fulfilled, successfulWidgetData)
      .addCase(getWidgetDataThunk.rejected, rejectedWidgetData)
      .addCase(
        getLeftNumberWidgetDataThunk.pending,
        loadingLeftNumberWidgetData
      )
      .addCase(
        getLeftNumberWidgetDataThunk.fulfilled,
        successfulLeftNumberWidgetData
      )
      .addCase(
        getLeftNumberWidgetDataThunk.rejected,
        rejectedLeftNumberWidgetData
      )
      .addCase(
        getRightNumberWidgetDataThunk.pending,
        loadingRightNumberWidgetData
      )
      .addCase(
        getRightNumberWidgetDataThunk.fulfilled,
        successfulRightNumberWidgetData
      )
      .addCase(
        getRightNumberWidgetDataThunk.rejected,
        rejectedRightNumberWidgetData
      );
  },
});

export const {
  applyStartupState,
  setStartupState,
  setFilters,
  addFilters,
  setMapAttribute,
  setWidgetAttribute,
  setWidgetData,
  setLeftWidgetAttribute,
  setRightWidgetAttribute,
  setDashboardLoading,
  setSelectedFeatures,
  setComparativeMode,
  setZoneMode,
  setFullScreenMode,
  setMapFeatures,
} = dashboardSlice.actions;

export const getMapData = debounceThunk(getMapDataThunk);
export const getAttributes = debounceThunk(getAttributesThunk);
export const getWidgetData = debounceThunk(getWidgetDataThunk);
export const getLeftNumberWidgetData = debounceThunk(
  getLeftNumberWidgetDataThunk
);
export const getRightNumberWidgetData = debounceThunk(
  getRightNumberWidgetDataThunk
);
export default dashboardSlice.reducer;
