import { memo, MouseEventHandler } from "react";
import { Status } from "../../../app/constants";
import "./profile-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  status?: Status;
  children?: String;
};

const ProfileButton: React.FC<Props> = (props) => {
  const { onClick, children, status } = props;

  return (
    <button className="profile-button" onClick={onClick}>
      {status !== Status.LOADING ? children : children}
    </button>
  );
};

export default memo(ProfileButton);
