import React, { memo, useRef, useState } from "react";
import Attribute, { AttributeTypes } from "../../../app/attribute";
import { Status } from "../../../app/constants";
import SelectAttributeButton from "../../buttons/select-attribute-button/select-attribute-button";
import { makeContainer } from "../../container/container";
import Loading from "../../loading/loading";
import PopMenu from "../../menu/pop-menu";
import NoData from "../../no-data/no-data";
import NumberWidgetText from "../../text/number-widget-text/number-widget-text";
import WidgetTitleText from "../../text/widget-title-text/widget-title-text";
import "./number-widget.css";

const WidgetContainer = makeContainer("number-widget-container");
const WidgetTitleContainer = makeContainer("number-widget-title-container");

type Props = {
  attribute: Attribute;
  attributes: Attribute[];
  value: string;
  status: Status;
  onAttributeSelected: (att: Attribute) => void;
};

const NumberWidget: React.FC<Props> = ({
  attribute,
  attributes,
  value,
  status,
  onAttributeSelected,
}) => {
  const [menuOpened, setMenuOpenened] = useState(false);
  const ref = useRef(null);
  const isLoading = status === Status.LOADING;
  const isError = status === Status.FAILED;
  const isSuccess = status === Status.SUCCEEDED;

  return (
    <WidgetContainer>
      {isLoading && <Loading />}
      {isError && <NoData />}
      {isSuccess && (
        <>
          <WidgetTitleContainer>
            <WidgetTitleText
              tooltipTitle={attribute?.friendly_name}
              tooltipDescription={attribute?.description}
            >
              {attribute?.friendly_name}
            </WidgetTitleText>
            <PopMenu
              open={menuOpened}
              entries={attributes
                ?.filter(
                  (att) =>
                    att.type === AttributeTypes.SCALAR &&
                    att.name !== attribute?.name
                )
                .map((att) => ({
                  label: att.friendly_name,
                  key: "",
                  onClick: () => onAttributeSelected(att),
                }))}
              containerRef={ref}
              searchable={true}
              onClose={() => setMenuOpenened(false)}
            >
              <SelectAttributeButton
                ref={ref}
                onClick={() => setMenuOpenened(true)}
              />
            </PopMenu>
          </WidgetTitleContainer>
          <NumberWidgetText className="number-widget-value">
            {value}
          </NumberWidgetText>
        </>
      )}
    </WidgetContainer>
  );
};

export default memo(NumberWidget);
