import HCaptcha from "@hcaptcha/react-hcaptcha";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BOLETO,
  brazilianStates,
  CREDIT_CARD,
  PIX,
  ShopPages,
} from "../../../app/constants";
import { getEncryptedCard } from "../../../app/pagseguro";
import { AppDispatch } from "../../../app/store";
import {
  fromCurrencyToStr,
  validateAddress,
  validateCEP,
  validateCPF,
  validateCreditCardDate,
  validateCreditCardNumber,
  validateCvv,
  validateName,
} from "../../../app/utils";
import ShopActionButton from "../../../components/buttons/shop-action-button/shop-action-button";
import { makeContainer } from "../../../components/container/container";
import ShopInputText from "../../../components/inputs/shop-input-text/shop-input-text";
import ShopRadioButton from "../../../components/inputs/shop-radio-button/shop-radio-button";
import ShopSelectText from "../../../components/inputs/shop-select-text/shop-select-text";
import ShopHeading, {
  Variant,
} from "../../../components/text/shop-heading/shop-heading";
import { selectAuthUserData } from "../../../features/auth/slice";
import { UserData } from "../../../features/auth/type";
import ShopService from "../../../features/shop/service";
import {
  createOrder,
  selectCartPrice,
  selectDiscountCode,
} from "../../../features/shop/slice";
import { CartPrice } from "../../../features/shop/type";
import CreateOrderModal from "../components/shop-create-order-modal/shop-create-order-modal";
import ShopPageContainer from "../components/shop-page-container/shop-page-container";
import ShopSpacer from "../components/shop-spacer/shop-spacer";
import "./checkout.css";

type Props = {
  isValid: boolean;
  paymentMethod?: string;
  setIsValid: (boolean) => void;
};

const FormContainer = makeContainer("form-container");
const FormLine = makeContainer("form-line");
const CaptchaContainer = makeContainer("captcha-container");

const holderFields = [
  "name",
  "cpf",
  "address",
  "addressNumber",
  "locality",
  "city",
  "zipCode",
  "state",
];

const holderFieldsValidation = [
  "isNameValid",
  "isCpfValid",
  "isAddressValid",
  "isAddressNumberValid",
  "isLocalityValid",
  "isCityValid",
  "isZipCodeValid",
  "isStateValid",
];

const creditCardFields = [
  "cardName",
  "creditCard",
  "expirationDate",
  "cvv",
  "installments",
];

const creditCardFieldsValidation = [
  "isCardNameValid",
  "isCreditCardValid",
  "isExpirationDateValid",
  "isCvvValid",
  "isInstallmentsValid",
];

// Métodos de Pagamento
const AvaiablePaymentMethods = [
  { label: "Cartão de Crédito", value: CREDIT_CARD },
  { label: "PIX", value: PIX },
  { label: "Boleto", value: BOLETO },
];

const OnlyBoletoPaymentMethods = [{ label: "Boleto", value: BOLETO }];

// Criando estado inicial
function buildInitialState(priceOptions) {
  const state = {};
  holderFields.forEach((f) => (state[f] = ""));
  creditCardFields.forEach((f) => (state[f] = ""));
  holderFieldsValidation.forEach((f) => (state[f] = true));
  creditCardFieldsValidation.forEach((f) => (state[f] = true));
  state["state"] = brazilianStates[0];
  state["installments"] = priceOptions[0];
  return state;
}

// Obtendo valores das parcelas
function toInstallmentOptions(price) {
  const options = [];
  const maxInstallments = 12;

  for (let i = 1; i <= maxInstallments; i++) {
    if (!price?.hasOwnProperty(i)) {
      continue;
    }

    options.push({
      label: `${i}x de ${fromCurrencyToStr(price[i].installment_value)}`,
      value: i,
    });
  }

  return options;
}

const PaymentForm: React.FC<Props> = ({ paymentMethod }) => {
  const dispatch: AppDispatch = useDispatch();
  const userData: UserData = useSelector(selectAuthUserData);
  const price: CartPrice = useSelector(selectCartPrice);
  const code = useSelector(selectDiscountCode);
  const captchaRef = useRef(null);
  const isCC = paymentMethod === CREDIT_CARD;

  const priceOptions = toInstallmentOptions(price);
  const [formState, setFormState] = useState(buildInitialState(priceOptions));
  const [isValid, setIsValid] = useState(false);
  const [showPay, setShowPay] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const set = (field, value) => {
    setFormState((s) => ({
      ...s,
      [field]: value,
    }));
  };

  const onNameChange = (newName) => {
    set("name", newName);
    set("isValidName", validateName(newName));
  };

  const onCpfChange = (newCpf) => {
    newCpf = newCpf.replace(/\D/g, "");
    set("cpf", newCpf);
    set("isCpfValid", validateCPF(newCpf));
  };

  const onAddressChange = (newAddress) => {
    set("address", newAddress);
    set("isAddressValid", validateAddress(newAddress));
  };

  const onAddressNumberChange = (newAddressNumber) => {
    set("addressNumber", newAddressNumber);
    set("isAddressNumberValid", validateAddress(newAddressNumber));
  };

  const onLocalityChange = (newLocality) => {
    set("locality", newLocality);
    set("isLocalityValid", validateAddress(newLocality));
  };

  const onCityChange = (newCity) => {
    set("city", newCity);
    set("isCityValid", validateAddress(newCity));
  };

  const onZipCodeChange = (newZipCode) => {
    newZipCode = newZipCode.replace(/\D/g, "");
    set("zipCode", newZipCode);
    set("isZipCodeValid", validateCEP(newZipCode));
  };

  const onCardNameChange = (newName) => {
    set("cardName", newName);
    set("isCardNameValid", validateName(newName));
  };

  const onCreditCardChange = (newCreditCard) => {
    const cleanedNumber = newCreditCard.replace(/\D/g, "");
    set("creditCard", cleanedNumber);
    set("isCreditCardValid", validateCreditCardNumber(cleanedNumber));
  };

  const onExpirationDateChange = (newExpirationDate) => {
    set("expirationDate", newExpirationDate);
    set("isExpirationDateValid", validateCreditCardDate(newExpirationDate));
  };

  const onCvvChange = (newCvv) => {
    set("cvv", newCvv);
    set("isCvvValid", validateCvv(newCvv));
  };

  useEffect(() => {
    const isCC = paymentMethod === CREDIT_CARD;
    const isAllValid = isCC
      ? [...holderFieldsValidation, ...creditCardFieldsValidation].every(
          (v) => formState[v]
        )
      : holderFieldsValidation.every((v) => formState[v]);

    const isAllFilled = isCC
      ? [...holderFields, ...creditCardFields].every((v) => formState[v] !== "")
      : holderFields.every((v) => formState[v] !== "");

    setIsValid(isAllValid && isAllFilled && captchaToken);
  }, [formState, paymentMethod, captchaToken]);

  useEffect(() => {
    captchaRef.current.resetCaptcha();
    setCaptchaToken("");
  }, [paymentMethod]);

  const placeOrder = () => {
    let createOrderRequest = {
      token: captchaToken,
      payment_method: paymentMethod,
      holder: {
        name: formState["name"],
        tax_id: formState["cpf"],
        email: userData.email,
        address: {
          street: formState["address"],
          number: formState["addressNumber"],
          locality: formState["locality"],
          city: formState["city"],
          region: formState["state"].label,
          region_code: formState["state"].value,
          country: "Brasil",
          postal_code: formState["zipCode"],
        },
      },
    };

    if (paymentMethod === CREDIT_CARD) {
      const encrytptedCard = getEncryptedCard({
        name: formState["cardName"],
        creditCard: formState["creditCard"],
        expirationDate: formState["expirationDate"],
        cvv: formState["cvv"],
      });

      createOrderRequest["encrypted_card"] = encrytptedCard;
      createOrderRequest["installments"] = formState["installments"].value;
    }

    if (code) {
      createOrderRequest["discount_code"] = code;
    }

    dispatch(
      createOrder({
        service: new ShopService(),
        order: createOrderRequest,
      })
    );

    setShowPay(true);
  };

  return (
    <FormContainer>
      <FormLine>
        <ShopInputText
          inputKey="name"
          label="Nome*"
          placeHolder="Nome Completo"
          grow={3}
          value={formState["name"]}
          invalid={!formState["isNameValid"]}
          onChange={(e) => onNameChange(e.target.value)}
        />
        <ShopInputText
          inputKey="cpf"
          label="CPF*"
          placeHolder="000.000.000-00"
          grow={2}
          value={formState["cpf"]}
          invalid={!formState["isCpfValid"]}
          onChange={(e) => onCpfChange(e.target.value)}
        />
      </FormLine>
      <FormLine>
        <ShopInputText
          inputKey="address"
          label="Endereço de Cobrança*"
          placeHolder="Endereço de Cobrança"
          grow={3}
          value={formState["address"]}
          invalid={!formState["isAddressValid"]}
          onChange={(e) => onAddressChange(e.target.value)}
        />
        <ShopInputText
          inputKey="addressNumber"
          label="Número*"
          placeHolder="Número"
          grow={1}
          value={formState["addressNumber"]}
          invalid={!formState["isAddressNumberValid"]}
          onChange={(e) => onAddressNumberChange(e.target.value)}
        />
      </FormLine>
      <FormLine>
        <ShopInputText
          inputKey="locality"
          label="Bairro*"
          placeHolder="Bairro"
          grow={1}
          value={formState["locality"]}
          invalid={!formState["isLocalityValid"]}
          onChange={(e) => onLocalityChange(e.target.value)}
        />
        <ShopInputText
          inputKey="city"
          label="Cidade*"
          placeHolder="Cidade"
          grow={1}
          value={formState["city"]}
          invalid={!formState["isCityValid"]}
          onChange={(e) => onCityChange(e.target.value)}
        />
      </FormLine>
      <FormLine>
        <ShopSelectText
          inputKey="states"
          label="Estado*"
          className=""
          placeHolder={"Estado"}
          options={brazilianStates}
          value={formState["state"]}
          onChange={(e) => set("state", e)}
        />
        <ShopInputText
          inputKey="zipcode"
          label="CEP*"
          placeHolder="00000-000"
          grow={0.7}
          value={formState["zipCode"]}
          invalid={!formState["isZipCodeValid"]}
          onChange={(e) => onZipCodeChange(e.target.value)}
        />
      </FormLine>
      <ShopSpacer space={5} />
      {isCC && (
        <>
          <ShopHeading variant={Variant.H2}>Dados do Cartão</ShopHeading>
          <FormLine>
            <ShopInputText
              inputKey="name"
              label="Nome*"
              placeHolder="Nome Impresso no Cartão"
              grow={3}
              value={formState["cardName"]}
              invalid={!formState["isCardNameValid"]}
              onChange={(e) => onCardNameChange(e.target.value)}
            />
          </FormLine>
          <FormLine>
            <ShopInputText
              inputKey="card"
              label="Número do Cartão*"
              placeHolder="Número do Cartão"
              grow={3}
              value={formState["creditCard"]}
              invalid={!formState["isCreditCardValid"]}
              onChange={(e) => onCreditCardChange(e.target.value)}
            />
            <ShopInputText
              inputKey="validity"
              label="Data de Validade*"
              placeHolder="MM/AAAA"
              grow={2}
              value={formState["expirationDate"]}
              invalid={!formState["isExpirationDateValid"]}
              onChange={(e) => onExpirationDateChange(e.target.value)}
            />
          </FormLine>
          <FormLine>
            <ShopInputText
              inputKey="cvv"
              label="CVV*"
              placeHolder="CVV"
              grow={1}
              value={formState["cvv"]}
              type={"password"}
              invalid={!formState["isCvvValid"]}
              onChange={(e) => onCvvChange(e.target.value)}
            />
            <ShopSelectText
              inputKey="installments"
              label="Parcelas*"
              placeHolder={"Nº de Parcelas"}
              options={priceOptions}
              value={formState["installments"]}
              onChange={(i) => set("installments", i)}
            />
          </FormLine>
          <ShopSpacer space={10} />{" "}
        </>
      )}

      <CaptchaContainer>
        <HCaptcha
          sitekey={process.env.REACT_APP_SITE_KEY}
          onVerify={setCaptchaToken}
          ref={captchaRef}
        />
      </CaptchaContainer>

      <ShopActionButton disable={!isValid} onClick={placeOrder}>
        Fazer Pedido
      </ShopActionButton>
      <CreateOrderModal
        show={showPay}
        onClose={() => {
          setShowPay(false);
        }}
      />
    </FormContainer>
  );
};

const Checkout: React.FC = () => {
  const navigate = useNavigate();
  const price: CartPrice = useSelector(selectCartPrice);
  const [paymentMethod, setPaymentMethod] = useState(CREDIT_CARD);
  const [ccDisabled, setCCDisabled] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (price.final_cost === 0) {
      setPaymentMethod(BOLETO);
      setCCDisabled(true);
    }
  }, [price]);

  return (
    <ShopPageContainer>
      <ShopHeading variant={Variant.H1}>Finalizar compra</ShopHeading>
      <ShopSpacer space={10} />
      <ShopHeading variant={Variant.H2}>
        Selecione um método de pagamento
      </ShopHeading>
      <ShopSpacer space={15} />
      <ShopRadioButton
        value={paymentMethod}
        options={ccDisabled ? OnlyBoletoPaymentMethods : AvaiablePaymentMethods}
        onChange={(value) => setPaymentMethod(value)}
      />
      <ShopSpacer space={15} />
      <PaymentForm
        isValid={isValid}
        setIsValid={setIsValid}
        paymentMethod={paymentMethod}
      />
      <ShopActionButton
        outline={true}
        onClick={() => {
          navigate(`/shop/${ShopPages.PRODUCT_SELECT}`);
        }}
      >
        Continuar comprando
      </ShopActionButton>
    </ShopPageContainer>
  );
};

export default Checkout;
