import { DivIcon, LatLng, LatLngBoundsExpression, Map } from "leaflet";
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import {
  CircleMarker,
  LayerGroup,
  MapContainer,
  Marker,
  Polygon,
  TileLayer,
  Tooltip,
  useMap,
} from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import {
  MapsTypes,
  MapsUrls,
  MarkColorModes,
  MarkSizeModes,
  Status,
} from "../../../../app/constants";
import { Feature, MapFeatures } from "../../../../app/maps";
import { AppDispatch } from "../../../../app/store";
import { getColor, getHighContrastColor } from "../../../../app/utils";
import {
  selectComparativeMode,
  selectFullScreenMode,
  selectSelectedFeatures,
} from "../../../../features/dashboard/slice";
import SettingsService from "../../../../features/settings/service";
import {
  getSettings,
  selectSettings,
} from "../../../../features/settings/slice";
import { Settings } from "../../../../features/settings/type";
import Loading from "../../../loading/loading";
import DataElegeMarker from "../../../marker/marker";
import NoData from "../../../no-data/no-data";
import "./map-viewer.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  map?: MapFeatures;
  status?: Status;
  fullScreenMode?: boolean;
  triggerCenterMap?: number;
  triggerZoomIn?: number;
  triggerZoomOut?: number;
  triggerResize?: number;
  handleFeatureClick?: (payload: any) => void;
  handleFeatureDblClick?: (payload: any) => void;
};

const STROKE_COLOR = "#27262e";
const STROKE_WEIGHT = 1;
const BRAZIL_CENTROID = new LatLng(-15.7801, -47.9292);
const BRAZIL_ENVELOP = [
  [-33.75117799399994, -73.990449969],
  [5.271841077000019, -28.84783881199999],
] as LatLngBoundsExpression;

const centerMap = (map: MapFeatures, mapRef: Map) => {
  if (map && mapRef) {
    if (map.envelope[0][0]) {
      const [[yMin, xMin], [yMax, xMax]] = map.envelope;
      mapRef.fitBounds([
        [yMin, xMin],
        [yMax, xMax],
      ]);
    }
  }
};

const zoomIn = (mapRef: Map) => {
  if (mapRef) {
    mapRef.setZoom(mapRef.getZoom() + 1);
  }
};

const zoomOut = (mapRef: Map) => {
  if (mapRef) {
    mapRef.setZoom(mapRef.getZoom() - 1);
  }
};

const MapControlFunctions: React.FC<Props> = ({
  map,
  triggerCenterMap,
  triggerZoomIn,
  triggerZoomOut,
  triggerResize,
}) => {
  const mapRef = useMap();

  useEffect(() => {
    const recenterMapView = () => {
      if (map) {
        centerMap(map, mapRef);
      } else {
        mapRef.fitBounds(BRAZIL_ENVELOP);
      }
    };

    recenterMapView();
  }, [map, triggerCenterMap, mapRef]);

  useEffect(() => {
    const increaseZoom = () => {
      if (triggerZoomIn) {
        zoomIn(mapRef);
      }
    };

    increaseZoom();
  }, [triggerZoomIn, mapRef]);

  useEffect(() => {
    const decreaseZoom = () => {
      if (triggerZoomOut) {
        zoomOut(mapRef);
      }
    };

    decreaseZoom();
  }, [triggerZoomOut, mapRef]);

  useEffect(() => {
    if (triggerResize) {
      mapRef.invalidateSize();
    }
  }, [triggerResize, mapRef]);

  return <></>;
};

const Tiles: React.FC<Props> = () => {
  const settings: Settings = useSelector(selectSettings);
  const [url, subdomains] = MapsUrls[settings.tiles_server];

  if (settings.tiles_server === MapsTypes.NONE) {
    return <></>;
  }

  return <TileLayer url={url} subdomains={subdomains} />;
};

const MapPolygons: React.FC<Props> = ({
  map,
  handleFeatureClick,
  handleFeatureDblClick,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const settings: Settings = useSelector(selectSettings);
  const comparativeMode: boolean = useSelector(selectComparativeMode);
  const layerRef = useRef(null);

  useEffect(() => {
    dispatch(getSettings(new SettingsService()));
  }, [dispatch]);

  return (
    <>
      <LayerGroup ref={layerRef}>
        {map?.features
          ?.filter((entry) => entry.type !== "Point")
          ?.map((feature, index) => (
            <Fragment key={`feature-polygon-${index}`}>
              {feature.polygons.map((polygon, subindex) => (
                <Polygon
                  key={`polygon-${index}-${subindex}-${feature.numericalValue}-${feature.dataelegeScaleValue}-${feature.linearScaleValue}`}
                  className="map-polygon"
                  bubblingMouseEvents={false}
                  fill={settings?.enable_choropleth_map}
                  stroke={settings?.enable_choropleth_map_borders}
                  fillOpacity={settings?.choropleth_map_opacity / 100}
                  color={STROKE_COLOR}
                  weight={STROKE_WEIGHT}
                  fillColor={getColor(feature, comparativeMode, settings)}
                  positions={polygon.coords}
                  eventHandlers={{
                    click: () => handleFeatureClick(feature),
                    dblclick: () => handleFeatureDblClick(feature),
                  }}
                >
                  {
                    <Tooltip
                      sticky={settings.sticky_tooltip}
                      direction="bottom"
                      offset={[0, 30]}
                      opacity={0.95}
                    >
                      {feature.name}
                      <br />
                      {feature.value}
                      <br />
                    </Tooltip>
                  }
                </Polygon>
              ))}
            </Fragment>
          ))}
      </LayerGroup>
    </>
  );
};

const MapPoints: React.FC<Props> = ({ map, handleFeatureClick }) => {
  const dispatch: AppDispatch = useDispatch();
  const settings: Settings = useSelector(selectSettings);
  const comparativeMode: boolean = useSelector(selectComparativeMode);

  useEffect(() => {
    dispatch(getSettings(new SettingsService()));
  }, [dispatch]);

  return (
    <>
      <LayerGroup>
        {map?.features
          ?.filter((entry) => entry.type === "Point")
          ?.map((entry, index) => (
            <CircleMarker
              key={`point-marker-${index}}`}
              stroke={true}
              fillOpacity={100}
              color={STROKE_COLOR}
              weight={STROKE_WEIGHT}
              fillColor={getColor(entry, comparativeMode, settings)}
              radius={5 + entry.linearScaleValue / 5}
              center={new LatLng(entry.centroid[0], entry.centroid[1])}
              eventHandlers={{
                click: () => handleFeatureClick(entry),
              }}
            >
              {
                <Tooltip
                  sticky={settings.sticky_tooltip}
                  direction="bottom"
                  offset={[0, 30]}
                  opacity={0.95}
                >
                  {entry.name}
                  <br />
                  {entry.value}
                  <br />
                </Tooltip>
              }
            </CircleMarker>
          ))}
      </LayerGroup>
    </>
  );
};

const MapMarkers: React.FC<Props> = ({
  map,
  handleFeatureClick,
  handleFeatureDblClick,
}) => {
  const settings: Settings = useSelector(selectSettings);
  const comparativeMode: boolean = useSelector(selectComparativeMode);

  return (
    <>
      <LayerGroup>
        {map?.features
          ?.filter((entry) => entry.type !== "Point" && settings?.enable_marker)
          ?.map((entry, index) => (
            <CircleMarker
              key={`marker-${index}}`}
              stroke={settings?.enable_marker}
              fillOpacity={settings?.enable_marker ? 100 : 0}
              color={STROKE_COLOR}
              weight={STROKE_WEIGHT}
              fillColor={
                settings?.marker_color_mode === MarkColorModes.SCALED_COLOR
                  ? getColor(entry, comparativeMode, settings)
                  : settings?.marker_color
              }
              radius={
                settings?.marker_size_mode === MarkSizeModes.SCALED_SIZE
                  ? 5 + entry.linearScaleValue / 5
                  : settings?.marker_size
              }
              center={new LatLng(entry.centroid[0], entry.centroid[1])}
              eventHandlers={{
                click: () => handleFeatureClick(entry),
                dblclick: () => handleFeatureDblClick(entry),
              }}
            >
              {
                <Tooltip
                  sticky={settings.sticky_tooltip}
                  direction="bottom"
                  offset={[0, 30]}
                  opacity={0.95}
                >
                  {entry.name}
                  <br />
                  {entry.value}
                  <br />
                </Tooltip>
              }
            </CircleMarker>
          ))}
      </LayerGroup>
    </>
  );
};

const MapSelectedMark: React.FC<Props> = ({ handleFeatureDblClick }) => {
  const map = useMap();
  const [zoomScale, setZoomScale] = useState(map.getZoom() ?? 4);
  const comparativeMode: boolean = useSelector(selectComparativeMode);
  const selectedFeatures: Feature[] = useSelector(selectSelectedFeatures);
  const settings: Settings = useSelector(selectSettings);
  const hasSelected = selectedFeatures?.length > 0;
  const feature = hasSelected ? selectedFeatures[0] : null;
  const [x, y] = feature ? feature?.centroid : [0, 0];

  const dataElegeIcon = new DivIcon({
    html: renderToString(
      <DataElegeMarker
        fill={
          feature
            ? getHighContrastColor(getColor(feature, comparativeMode, settings))
            : "white"
        }
      />
    ),
    iconSize: [4 * zoomScale, 8 * zoomScale],
    popupAnchor: [1 * zoomScale, -1 * zoomScale],
  });

  useEffect(() => {
    const handleZoom = () => {
      const zoom = map.getZoom();
      setZoomScale(zoom);
    };

    map.on("zoom", handleZoom);

    return () => {
      map.off("zoom", handleZoom);
    };
  }, [map]);

  return (
    <LayerGroup>
      {hasSelected && (
        <Marker
          eventHandlers={{
            click: () => handleFeatureDblClick(selectedFeatures[0]),
          }}
          position={[x, y]}
          icon={dataElegeIcon}
        />
      )}
    </LayerGroup>
  );
};

const LoadingContainer = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { fullScreenMode, status, children } = props;
  const isLoading = status === Status.LOADING;
  const isFailed = status === Status.FAILED;

  return (
    <div
      ref={ref}
      className={`map-viewer-container ${
        fullScreenMode ? "map-viewer-container-fullscreen" : ""
      }`}
    >
      {children}
      {isLoading && (
        <div
          className={`map-viewer-loading-overlay ${
            fullScreenMode ? "map-viewer-loading-overlay-fullscreen" : ""
          }`}
        >
          <Loading />
        </div>
      )}
      {isFailed && (
        <div
          className={`map-viewer-loading-overlay ${
            fullScreenMode ? "map-viewer-loading-overlay-fullscreen" : ""
          }`}
        >
          <NoData />
        </div>
      )}
    </div>
  );
});

const MapViewer: React.FC<Props> = ({
  map,
  status,
  triggerCenterMap,
  triggerZoomIn,
  triggerZoomOut,
  handleFeatureClick,
  handleFeatureDblClick,
}) => {
  const { width, height, ref } = useResizeDetector({
    refreshMode: "debounce",
    refreshRate: 50,
  });
  const [triggerResize, setTriggerResize] = useState(0);
  const fullScreenMode: boolean = useSelector(selectFullScreenMode);

  useEffect(() => {
    setTriggerResize((t) => t + 1);
  }, [width, height]);

  return (
    <LoadingContainer ref={ref} fullScreenMode={fullScreenMode} status={status}>
      <MapContainer
        className={`map-viewer-container ${
          fullScreenMode ? "map-viewer-container-fullscreen" : ""
        }`}
        center={BRAZIL_CENTROID}
        zoomControl={false}
        doubleClickZoom={false}
        minZoom={3}
        maxZoom={16}
      >
        <MapControlFunctions
          map={map}
          triggerCenterMap={triggerCenterMap}
          triggerZoomIn={triggerZoomIn}
          triggerZoomOut={triggerZoomOut}
          triggerResize={triggerResize}
        />
        <Tiles />
        <MapSelectedMark handleFeatureDblClick={handleFeatureDblClick} />
        <MapPolygons
          map={map}
          handleFeatureClick={handleFeatureClick}
          handleFeatureDblClick={handleFeatureDblClick}
        />
        <MapPoints map={map} handleFeatureClick={handleFeatureClick} />
        <MapMarkers
          map={map}
          handleFeatureClick={handleFeatureClick}
          handleFeatureDblClick={handleFeatureDblClick}
        />
      </MapContainer>
    </LoadingContainer>
  );
};

export default MapViewer;
